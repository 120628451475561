import React from "react";
import SessionVideoCard from "./SessionVideoCard";
import SessionDetailCard from "./SessionDetailCard";
import ButtonSection from "./ButtonSection";
import NotesSection from "./NotesSection";
import useTranslations from "../../../custom-hooks/useTranslations";
import { ZoomImageContainer } from "../chat/Events";

const SessionDetail = (props) => {
  return (
    <>
      <div className="cis-schedule-detail">
        <SessionVideoCard {...props} />
        {/* <ButtonSection {...props} /> */}
        <div className="space-20"></div>
        <hr className="cis-devider-2" />
        <SessionDetailCard {...props} />
        <div className="space-20"></div>
        <hr className="cis-devider-2" />
        <div className="space-20"></div>
        <NotesSection {...props} />
      </div>
      <div className="space-20"></div>
      <div className="space-20"></div>
      <ZoomImageContainer />
    </>
  );
};

export default SessionDetail;
