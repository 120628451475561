import React, { useEffect, useState } from 'react';
import '../../Common.css';
import './QuickCalls.css';
import {
	Alert,
	AlertTitle,
	Avatar,
	Card,
	CardContent,
	CardHeader,
	DialogActions,
	DialogTitle,
	FormControl,
	FormControlLabel,
	Radio,
	RadioGroup,
	Switch,
	Typography
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { axiosPost } from '../../services/api';
import { Link, useNavigate } from 'react-router-dom';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import { Button } from '@mui/material';
import Grid from '@mui/material/Grid';
import Rating from '@mui/material/Rating';
import PlayCircleOutlinedIcon from '@mui/icons-material/PlayCircleOutlined';
import VideocamOutlinedIcon from '@mui/icons-material/VideocamOutlined';
import PaymentsOutlinedIcon from '@mui/icons-material/PaymentsOutlined';
import StarsOutlinedIcon from '@mui/icons-material/StarsOutlined';
import YasaHeader from '../common-components/YasaHeader';
import { useDispatch, useSelector } from 'react-redux';
import { saveSessionStartTime } from '../../actions/saveStartSessionTimeAction';
import { saveSessionRatings } from '../../actions/saveSessionRatingAction';
import { getTutorDashboard } from '../../actions/getTutorDashboardAction';
import { receiveQuickCall } from '../../actions/receiveQuickCallAction';
import { rejectQuickCalls } from '../../actions/rejectQuickCallAction';
import { getTutorDetailedFeedBack } from '../../actions/getTutorFeedBackAction';
import { getSessionToday } from '../../actions/getSessionTodayAction';
import { getTutorTransaction } from '../../actions/getTutortransactionAction';
import { getTutorOnline } from '../../actions/tutorOnlineStatus';
import callRinging from '../../assets/audio/ring.wav';
import useTranslations from '../../custom-hooks/useTranslations';
import Loading from '../loader/Loading';
import generateAgoraToken from '../../services/AgoraTokenService';
import { encryptData } from '../../services/encryptDecrypt';
import { API_BASE_URL } from '../../config/apiConfig';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useSocket from '../../custom-hooks/useSocket';
import { useTranslation } from 'react-i18next';
import CircularProgress from '@mui/material/CircularProgress';

const TutorQuickCall = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { t, i18n } = useTranslation();
	const { isConnected, socket } = useSocket()
	const [audio] = useState(new Audio(callRinging));
	const [selectedValue, setSelectedValue] = useState('');
	const [selectedReason, setSelectedReason] = useState('');
	const [studentRating, setStudentRating] = useState(0.0);
	const [sessionRating, setSessionRating] = useState(0.0);
	const [callModal, setCallModal] = useState(false);
	const [openCallAcceptanceModal, setOpenCallAcceptanceModal] = useState(false);
	const [cancellationBox, setCancellationBox] = useState(false);
	const [cancellationReason, setCancellationReason] = useState([]);
	const [cancellationOpenModal, setCancellationOpenModal] = useState(false);
	const [callingStudentInfo, setCallingStudentInfo] = useState({});
	const [questionType, setQuestionType] = useState([]);
	const [tutorOnlineState, setTutorOnlineState] = useState({});
	const sessionCost = JSON.parse(sessionStorage.getItem("SessionCost"));
	const savedSession = useSelector(({ saveSessionStartTimeReducer }) => saveSessionStartTimeReducer?.savedSession);
	const tutorDashboard = useSelector(({ getTutorDashboardReducer }) => getTutorDashboardReducer?.tutorDashboard);
	
	const helperData = useSelector(({ helperDataReducer }) => helperDataReducer?.helperData);
	// const TutorOnlineSuccess = useSelector(({ onlineTutorReducer }) => onlineTutorReducer?.onlineTutor);

	const profileInfo = JSON.parse(sessionStorage.getItem("ProfileInfo"));
	const [openratingmodel, setOpenratingmodel] = useState(sessionStorage.getItem('SessionEnd') ? true : false);
	const user = JSON.parse(sessionStorage.getItem("userInfo"));
	const tutorResponse = JSON.parse(sessionStorage.getItem('TutorResponse'));
	const [isAudioPlaying, setIsAudioPlaying] = useState(false); // Track audio playing state
	const [isOnline, setIsOnline] = useState(profileInfo?.OnlineStatus);
	const translations = useTranslations();
	const translation = translations[26]?.QuickCall;
	const commonFrontEnd = translations[21]?.CommonFrontend;
	const [hireTutorModal, setHireTutorModal] = useState(false);
	const [hireCallingInfo, setHireCallingInfo] = useState({});
	const [openPaymentProcessModal, setPaymentProcessModal] = useState(false);
	const [hireTutorRatingModal, setHireTutorRatingModal] = useState(false);
	const privateTutorSession = sessionStorage.getItem('PrivateTutorSession') || false;
	const privateTutorSessionID = sessionStorage.getItem('SessionID') | null;
	const tutorDashboardLan = translations[28]?.TutorDashboard;
	const [receiveQuickCalls, setReceiveQuickCalls] = useState(false)
	const [receiveProCalls, setReceiveProCalls] = useState(false)
	const connected = translations[26]?.QuickCall;
	const privateClassDashboard = translations[8]?.PrivateClassDashboard
	const [openCallCancelModal, setOpenCallCancelModal] = useState(false);
	const [onAgreeLoading, setOnAgreeLoading] = useState(false)
	const [onAgreeQcLoading, setOnAgreeQcLoading] = useState(false)
	const [isLoading, setIsLoading] = useState(true)
	const [isOffline, setIsOffline] = useState(false);
	const [micPermission, setMicPermission] = useState(null);


	const onConnectTutor = (value) => {
		const profile = JSON.parse(sessionStorage.getItem('ProfileInfo'))
		// const receive_pro_call_val_as = localStorage.getItem("receive_pro_call");
		// const receive_quick_call_val_as = localStorage.getItem("receive_quick_call");
	
		if (profile && JSON.parse(profile?.ReceiveQuickCall) === false) return;
	
		const object = JSON.parse(value);
		if (object.StatusCode !== 200) return;


		const userState = localStorage.getItem("userState");
	
		if (!userState) {
			if (!object.Data.IsDecline) {
				localStorage.setItem("userState", JSON.stringify(object.Data));
				sessionStorage.setItem('TutorResponse', JSON.stringify(object.Data));
				setCallModal(true);
	
				if (!isAudioPlaying) {
					audio.loop = true;
					audio.play();
					setIsAudioPlaying(true);
				}
	
				setCallingStudentInfo(object.Data);
				localStorage.setItem("QCImage", object.Data.ImageURL);

				const onlineObj = {};
				onlineObj['EndPoint'] = '/TutorOnline';
				onlineObj['DeviceID'] = localStorage.getItem('deviceId');
				onlineObj['authorization'] = JSON.parse(sessionStorage.getItem("loginToken"))?.idToken;
				onlineObj['OnlineStatus'] = true; 
				onlineObj['OnlineStatusID'] = 2; 
				onlineObj['ReceiveQuickCall'] = true; 
				onlineObj['ReceiveHireTutorCall'] = JSON.parse(profile?.ReceiveHireTutorCall); 

				socket?.emit('PTModule', JSON.stringify(onlineObj))
	
				// dispatch(getTutorOnline({ OnlineStatus: true, OnlineStatusID: 2, ReceiveQuickCall: true, ReceiveHireTutorCall: Boolean(Number(receive_pro_call_val_as)) }));
				dispatch(receiveQuickCall({ SessionId: object.Data.QuestionID }));
			}
		} else {
			const userStateobj = JSON.parse(userState);
	
			if (object.Data.QuestionID === userStateobj.QuestionID && object.Data.IsDecline) {
				audio.pause();
				audio.currentTime = 0;
				setCallModal(false);
				setOpenCallCancelModal(true);

				const onlineObj = {};
				onlineObj['EndPoint'] = '/TutorOnline';
				onlineObj['DeviceID'] = localStorage.getItem('deviceId');
				onlineObj['authorization'] = JSON.parse(sessionStorage.getItem("loginToken"))?.idToken;
				onlineObj['OnlineStatus'] = true; 
				onlineObj['OnlineStatusID'] = 1; 
				onlineObj['ReceiveQuickCall'] = true; 
				onlineObj['ReceiveHireTutorCall'] = JSON.parse(profile?.ReceiveHireTutorCall); 

				socket?.emit('PTModule', JSON.stringify(onlineObj))

				// dispatch(getTutorOnline({ OnlineStatus: true, OnlineStatusID: 1, ReceiveQuickCall: true, ReceiveHireTutorCall: Boolean(Number(receive_pro_call_val_as)) }));
				setCallingStudentInfo({});
				localStorage.removeItem("userState");
				sessionStorage.removeItem("TutorResponse");
			}
		}
	};
	
	// useEffect(() => {
	// 	localStorage.removeItem("userState")
	// 	localStorage.setItem("receive_pro_call", 0)
	// 	localStorage.setItem("receive_quick_call", 0)
	// 	socket?.emit("join", "QC");

	// 	if (profileInfo) {
	// 		profileInfo.OnlineStatus = false;
	// 		sessionStorage.setItem('ProfileInfo', JSON.stringify(profileInfo));

	// 		const onlineObj = {};
	// 		onlineObj['EndPoint'] = '/TutorOnline';
	// 		onlineObj['DeviceID'] = localStorage.getItem('deviceId');
	// 		onlineObj['authorization'] = JSON.parse(sessionStorage.getItem("loginToken"))?.idToken;
	// 		onlineObj['OnlineStatus'] = false; 
	// 		onlineObj['OnlineStatusID'] = 3; 
	// 		onlineObj['ReceiveQuickCall'] = false; 
	// 		onlineObj['ReceiveHireTutorCall'] = false; 

	// 		socket?.emit('PTModule', JSON.stringify(onlineObj))

	// 		// dispatch(getTutorOnline({ OnlineStatus: false, OnlineStatusID: 3, ReceiveQuickCall: false, ReceiveHireTutorCall: false }));
	// 	}

	// }, [])

	useEffect(() => {
		if (savedSession) {
			setCallModal(false);
			// localStorage.removeItem('CallDuration')
			const userState = JSON.parse(localStorage.getItem("userState"))
			if (userState != null) {
				userState.IsAccept = true;
				userState.requestType = "2";
				userState.TutorID = profileInfo?.ID;
				userState.TutorImage = profileInfo?.ProfilePictureUrl;
				userState.TutorName = profileInfo?.UserName;
				userState.TutorSubject = profileInfo?.Topics?.map(topic => topic.Value).join(', ')
				socket.emit("QCQuestions", JSON.stringify(userState), 'QC');
				sessionStorage.setItem('TutorResponse', JSON.stringify(userState));

				const userUid = user?.uid;
				const channelName = `channel${userState?.QuestionID}`;
				generateAgoraToken(userUid, 1, channelName).then((res) => {
					localStorage.setItem("rtmToken", JSON.stringify(res.RtmToken));
					localStorage.setItem("rtcToken", JSON.stringify(res.RtcToken));
					navigate(`/quick-calls-room/${encryptData(userState?.QuestionID)}`);
				});
			}
		} else {
			//  show pop up related that this session is accepted by other tutor
			if (savedSession !== undefined) {
				setCallModal(false);
				setOpenCallAcceptanceModal(true);
				setCallingStudentInfo({})
				localStorage.removeItem("userState")
				sessionStorage.removeItem("TutorResponse")

				const onlineObj = {};
				onlineObj['EndPoint'] = '/TutorOnline';
				onlineObj['DeviceID'] = localStorage.getItem('deviceId');
				onlineObj['authorization'] = JSON.parse(sessionStorage.getItem("loginToken"))?.idToken;
				onlineObj['OnlineStatus'] = true; 
				onlineObj['OnlineStatusID'] = 1; 
				onlineObj['ReceiveQuickCall'] = receiveQuickCalls; 
				onlineObj['ReceiveHireTutorCall'] = receiveProCalls; 

				socket?.emit('PTModule', JSON.stringify(onlineObj))

				// dispatch(getTutorOnline({ OnlineStatus: true, OnlineStatusID: 1, ReceiveQuickCall: receiveQuickCalls, ReceiveHireTutorCall: receiveProCalls }));
			}
		}
	}, [savedSession, socket]);

	const onAgreeClickHandler = () => {
		// Pause the audio when "Agree" is clicked
		audio.pause();
		audio.currentTime = 0;
		// Additional logic
		audio.loop = false;
		setIsAudioPlaying(false);
		setOnAgreeQcLoading(true)
		dispatch(saveSessionStartTime({ QuickCallID: callingStudentInfo.QuestionID }));
	}

	const handleOpenmodel = (receiveQuickCalls, receiveProCalls) => {
		if (receiveQuickCalls == true || receiveProCalls == true) {
			setIsOnline(true);
			handleOpenmodelAndData(receiveQuickCalls, receiveProCalls, true)
		} else {
			setIsOnline(false);
			handleOpenmodelAndData(receiveQuickCalls, receiveProCalls, false)
		}
	};

	const handleReceiveQuickCalls = async () => {
		localStorage.removeItem("userState")
		// if (receiveQuickCalls) {
		// 	setReceiveQuickCalls(false)
		// 	handleOpenmodel(false, receiveProCalls)
		// 	// localStorage.setItem('receive_quick_call', 0);
		// } else {
		// 	setReceiveQuickCalls(true)
		// 	handleOpenmodel(true, receiveProCalls)
		// 	// localStorage.setItem('receive_quick_call', 1);
		// }

		if (receiveQuickCalls) {
			setReceiveQuickCalls(false)
			handleOpenmodel(false, receiveProCalls)

		} else {
			if (micPermission) {
				setReceiveQuickCalls(true)
				handleOpenmodel(true, receiveProCalls)

			} else {
				try {
					const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
					// Permission granted
					checkOnlineStatus();
					setMicPermission(true);
					setReceiveQuickCalls(true)
					handleOpenmodel(true, receiveProCalls)
					// Don't forget to stop the stream if you no longer need it
					stream.getTracks().forEach(track => track.stop());
				} catch (error) {
					setMicPermission(false);
					console.error('Error accessing microphone:', error);
					toast.error('Permission denied or browser does not support microphone access')
				}
			}
		}
	};

	const handleReceiveProCalls = async () => {
		localStorage.removeItem("userState");
		// if (receiveProCalls) {
		// 	setReceiveProCalls(false)
		// 	handleOpenmodel(receiveQuickCalls, false)
		// 	// localStorage.setItem('receive_pro_call', 0);
		// } else {
		// 	setReceiveProCalls(true)
		// 	handleOpenmodel(receiveQuickCalls, true)
		// 	// localStorage.setItem('receive_pro_call', 1);
		// }

		if (receiveProCalls) {
			setReceiveProCalls(false)
			handleOpenmodel(receiveQuickCalls, false)
		} else {
			if (micPermission) {
				setReceiveProCalls(true);
				handleOpenmodel(receiveQuickCalls, true);
			} else {
				try {
					const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
					// Permission granted
					checkOnlineStatus();
					setMicPermission(true);
					setReceiveProCalls(true);
					handleOpenmodel(receiveQuickCalls, true);
					// Don't forget to stop the stream if you no longer need it
					stream.getTracks().forEach(track => track.stop());
				} catch (error) {
					setMicPermission(false);
					console.error('Error accessing microphone:', error);
					toast.error('Permission denied or browser does not support microphone access')
				}
			}
		}
	};

	const handleOpenmodelAndData = (receiveQuickCalls, receiveProCalls, isOnline) => {
		if (!isOnline) {
			profileInfo.OnlineStatus = false;
			profileInfo.ReceiveQuickCall = receiveQuickCalls;
			profileInfo.ReceiveHireTutorCall = receiveProCalls;
			sessionStorage.setItem('ProfileInfo', JSON.stringify(profileInfo));

			const onlineObj = {};
			onlineObj['EndPoint'] = '/TutorOnline';
			onlineObj['DeviceID'] = localStorage.getItem('deviceId');
			onlineObj['authorization'] = JSON.parse(sessionStorage.getItem("loginToken"))?.idToken;
			onlineObj['OnlineStatus'] = false; 
			onlineObj['OnlineStatusID'] = 3; 
			onlineObj['ReceiveQuickCall'] = receiveQuickCalls; 
			onlineObj['ReceiveHireTutorCall'] = receiveProCalls; 

			socket?.emit('PTModule', JSON.stringify(onlineObj))

			// dispatch(getTutorOnline({ OnlineStatus: false, OnlineStatusID: 3, ReceiveQuickCall: receiveQuickCalls, ReceiveHireTutorCall: receiveProCalls }));

		} else {
			if (receiveQuickCalls && receiveProCalls) {
				socket?.on("QCQuestions", onConnectTutor);
				socket?.emit("join", "QC");
				socket?.on('responseEvent', hireTutorHandler)
			}
			else if (receiveQuickCalls) {
				socket?.on("QCQuestions", onConnectTutor);
				socket?.emit("join", "QC");
			}
			else if (receiveProCalls) {
				socket?.on('responseEvent', hireTutorHandler)
			}
			else {
				socket?.off("QCQuestions")
				socket?.off("responseEvent")
			}
			
			profileInfo.OnlineStatus = true;
			profileInfo.ReceiveQuickCall = receiveQuickCalls;
			profileInfo.ReceiveHireTutorCall = receiveProCalls;
			sessionStorage.setItem('ProfileInfo', JSON.stringify(profileInfo));

			// dispatch(getTutorOnline({ OnlineStatus: true, OnlineStatusID: 1, ReceiveQuickCall: receiveQuickCalls, ReceiveHireTutorCall: receiveProCalls }));
			// const payload = {};
			// payload['authorization'] = JSON.parse(sessionStorage.getItem('loginToken')).idToken;
			// payload['DeviceID'] = localStorage.getItem('deviceId');
			// payload['EndPoint'] = '/TutorOnline'

			// socket.emit('PTModule', JSON.stringify(payload));

			const onlineObj = {};
			onlineObj['EndPoint'] = '/TutorOnline';
			onlineObj['DeviceID'] = localStorage.getItem('deviceId');
			onlineObj['authorization'] = JSON.parse(sessionStorage.getItem("loginToken"))?.idToken;
			onlineObj['OnlineStatus'] = true; 
			onlineObj['OnlineStatusID'] = 1; 
			onlineObj['ReceiveQuickCall'] = receiveQuickCalls; 
			onlineObj['ReceiveHireTutorCall'] = receiveProCalls; 

			socket?.emit('PTModule', JSON.stringify(onlineObj))
		}

	}

	const handleCloseHireModal = (event, reason) => {
		if (reason && reason === "backdropClick" && "escapeKeyDown") {
			return;
		}
		setHireTutorModal(false);
	}

	const onDisagreeClickHandler = () => {
		const onlineObj = {};
		onlineObj['EndPoint'] = '/TutorOnline';
		onlineObj['DeviceID'] = localStorage.getItem('deviceId');
		onlineObj['authorization'] = JSON.parse(sessionStorage.getItem("loginToken"))?.idToken;
		onlineObj['OnlineStatus'] = true; 
		onlineObj['OnlineStatusID'] = 1; 
		onlineObj['ReceiveQuickCall'] = receiveQuickCalls; 
		onlineObj['ReceiveHireTutorCall'] = receiveProCalls; 

		socket?.emit('PTModule', JSON.stringify(onlineObj))
		// dispatch(getTutorOnline({ OnlineStatus: true, OnlineStatusID: 1, ReceiveQuickCall: receiveQuickCalls, ReceiveHireTutorCall: receiveProCalls }));
		setCallModal(false);
		setCancellationBox(true);
	}

	const onSaveFeedBackHandler = async () => {
		const profile = JSON.parse(sessionStorage.getItem('ProfileInfo'));
		const qcStatus = JSON.parse(profile?.ReceiveQuickCall);
		const hcStatus = JSON.parse(profile?.ReceiveHireTutorCall);
		const ratingsObj = {
			SessionID: tutorResponse?.QuestionID,
			StudentRating: parseInt(studentRating),
			SessionRating: parseInt(sessionRating),
			QuestionRating: parseInt(selectedValue)
		};
		dispatch(saveSessionRatings(ratingsObj));
		sessionStorage.removeItem('SessionEnd', false);
		sessionStorage.removeItem('TutorResponse');
		localStorage.removeItem("userState")
		setOpenratingmodel(false);
		setReceiveProCalls(hcStatus);
		setReceiveQuickCalls(qcStatus);
		setTutorOnlineState({
			"OnlineStatus": true,
			"OnlineStatusID": 1,
			"ReceiveQuickCall": qcStatus,
			"ReceiveHireTutorCall": hcStatus
		});
		const onlineObj = {};
		onlineObj['EndPoint'] = '/TutorOnline';
		onlineObj['DeviceID'] = localStorage.getItem('deviceId');
		onlineObj['authorization'] = JSON.parse(sessionStorage.getItem("loginToken"))?.idToken;
		onlineObj['OnlineStatus'] = true; 
		onlineObj['OnlineStatusID'] = 1; 
		onlineObj['ReceiveQuickCall'] = qcStatus; 
		onlineObj['ReceiveHireTutorCall'] = hcStatus; 

		socket?.emit('PTModule', JSON.stringify(onlineObj));
		if (qcStatus) {
			socket?.on("QCQuestions", onConnectTutor);
			socket?.emit("join", "QC");
		}
		if (hcStatus) {
			socket?.on('responseEvent', hireTutorHandler)
		}
	};

	const onDiscloseHandler = () => {
		setOpenCallAcceptanceModal(false);
	}

	const onYesClickHandler = () => {
		// Pause the audio when "Yes" is clicked
		audio.pause();
		// Additional logic
		audio.loop = false;
		setIsAudioPlaying(false);
		setCancellationBox(false);
		setCancellationOpenModal(true);
	};

	const onNoClickHandler = () => {
		setCancellationBox(false);
		setCancellationOpenModal(false);
		setCallModal(true);
	}


	const handleChange = (event) => {
		setSelectedValue(event.target.value);
	};


	const handleStudentRatingChange = (event) => {
		setStudentRating(event.target.value);
	};

	const handleSessionRatingChange = (event) => {
		setSessionRating(event.target.value);
	}

	const onCancellationHandleChange = (event) => {
		setSelectedReason(event.target.value);
	};


	const onSubmitCancellationReason = () => {
		const userState = JSON.parse(localStorage.getItem("userState"))
		const payload = {};
		payload['SessionID'] = userState?.QuestionID;
		payload['ReasonID'] = parseInt(selectedReason);
		localStorage.removeItem("isUserActive")
		localStorage.removeItem("userState")
		dispatch(rejectQuickCalls(payload));
		setCancellationOpenModal(false);
	}

	useEffect(() => {
		if (privateTutorSession) {
			setHireTutorRatingModal(true);
		}
	}, [privateTutorSession]);


	useEffect(() => {
		const handleVisibilityChange = () => {
			// console.log('isConnected: ', isConnected);
			// console.log('socket: ', socket.id);
		  if (!isConnected) {
			// PC woke up from sleep
			console.log('awake....')
			const profile = JSON.parse(sessionStorage.getItem('ProfileInfo'));
			profile.OnlineStatus = false;
			profile.ReceiveQuickCall = false;
			profile.ReceiveHireTutorCall = false;

			sessionStorage.setItem('ProfileInfo', JSON.stringify(profile));
			setReceiveProCalls(false);
			setReceiveQuickCalls(false);
			setTutorOnlineState((prev) => ({...prev, OnlineStatus: false}));

		  }
		};
	
		document.addEventListener('visibilitychange', handleVisibilityChange);
	
		// Cleanup function to remove event listener
		return () => {
		  document.removeEventListener('visibilitychange', handleVisibilityChange);
		};
	}, [isConnected]);
	

	const onSavePrivateTutorFeedBack = async () => {
		try {
			const profile = JSON.parse(sessionStorage.getItem('ProfileInfo'));
			const qcStatus = JSON.parse(profile?.ReceiveQuickCall);
			const hcStatus = JSON.parse(profile?.ReceiveHireTutorCall);
			setHireTutorRatingModal(false)
			setReceiveProCalls(hcStatus);
			setReceiveQuickCalls(qcStatus);
			setTutorOnlineState({
				"OnlineStatus": true,
				"OnlineStatusID": 1,
				"ReceiveQuickCall": qcStatus,
				"ReceiveHireTutorCall": hcStatus
			});
			const onlineObj = {};
			onlineObj['EndPoint'] = '/TutorOnline';
			onlineObj['DeviceID'] = localStorage.getItem('deviceId');
			onlineObj['authorization'] = JSON.parse(sessionStorage.getItem("loginToken"))?.idToken;
			onlineObj['OnlineStatus'] = true; 
			onlineObj['OnlineStatusID'] = 1; 
			onlineObj['ReceiveQuickCall'] = qcStatus; 
			onlineObj['ReceiveHireTutorCall'] = hcStatus; 

			socket?.emit('PTModule', JSON.stringify(onlineObj));
			if (qcStatus) {
				socket?.on("QCQuestions", onConnectTutor);
				socket?.emit("join", "QC");
			}
			if (hcStatus) {
				socket?.on('responseEvent', hireTutorHandler)
			}

			const payload = {
				"SessionID": privateTutorSessionID,
				"Ratings": studentRating,
				"CallRating": sessionRating
			};

			const response = await axiosPost(`${API_BASE_URL}/SavePrivateTutorFeedback`, payload);
			// const onlineStatusID = 1; // online status = 1
			// dispatch(getTutorOnline({ OnlineStatus: true, OnlineStatusID: onlineStatusID, ReceiveQuickCall: receiveQuickCalls, ReceiveHireTutorCall: receiveProCalls }));
			
			if (response.data.IsSuccess) {
				toast(response.data.Message);
				sessionStorage.removeItem('PrivateTutorSession');
				sessionStorage.removeItem('TutorResponse');
				localStorage.removeItem("userState")
			} else {
				toast(response.data.Message);
			}

		} catch (error) {
			console.error('An error occurred while saving private tutor feedback:', error);
		}
	};



	useEffect(() => {
		dispatch(getTutorDashboard());
		dispatch(getSessionToday());
		dispatch(getTutorTransaction({ FilterType: 1 }));
	}, [dispatch]);

	const hireTutorHandler = (response) => {
		const userState = localStorage.getItem("userState");
		const responseData = JSON.parse(response);
		// let receive_pro_call_val_as_hr = localStorage.getItem("receive_pro_call");
		// let receive_quick_call_val_as_hr = localStorage.getItem("receive_quick_call");
		const profile = JSON.parse(sessionStorage.getItem('ProfileInfo'));
		const onlineStatusID = responseData.Message === 'Tutor Online!' ? 2 : 1;
		const studentRequest = responseData?.TutorOnline || responseData.Data[0];
	
		if (!userState) {
			handleUserStateNull(studentRequest);
		} else {
			const responseDatahr = JSON.parse(userState);
			handleUserStateNotNull(studentRequest, responseDatahr);
		}
	
		function handleUserStateNull(studentRequest) {
			if (studentRequest?.TutorID?.toString() === profileInfo?.ID.toString()) {
				if (studentRequest.requestType.toString() === '1' && studentRequest.IsDecline === false && studentRequest?.isPaymentDone.toString() === '0') {
					localStorage.setItem("userState", JSON.stringify(studentRequest));
					sessionStorage.setItem("TutorResponse", JSON.stringify(studentRequest));
					setHireTutorModal(true);
					handleAudioPlay();
					setHireCallingInfo(studentRequest);

					const onlineObj = {};
					onlineObj['EndPoint'] = '/TutorOnline';
					onlineObj['DeviceID'] = localStorage.getItem('deviceId');
					onlineObj['authorization'] = JSON.parse(sessionStorage.getItem("loginToken"))?.idToken;
					onlineObj['OnlineStatus'] = true; 
					onlineObj['OnlineStatusID'] = onlineStatusID; 
					onlineObj['ReceiveQuickCall'] =  JSON.parse(profile?.ReceiveQuickCall); 
					onlineObj['ReceiveHireTutorCall'] =  JSON.parse(profile?.ReceiveHireTutorCall); 

					socket?.emit('PTModule', JSON.stringify(onlineObj))

					// dispatch(getTutorOnline({ OnlineStatus: true, OnlineStatusID: onlineStatusID, ReceiveQuickCall: Boolean(Number(receive_quick_call_val_as_hr)), ReceiveHireTutorCall: Boolean(Number(receive_pro_call_val_as_hr)) }));
				} else {
					handleNonHireCase(studentRequest);
				}
			}
		}
	
		function handleNonHireCase(studentRequest) {
			setHireTutorModal(false);
			handleAudioPause();
			setHireCallingInfo(studentRequest);

			const onlineObj = {};
			onlineObj['EndPoint'] = '/TutorOnline';
			onlineObj['DeviceID'] = localStorage.getItem('deviceId');
			onlineObj['authorization'] = JSON.parse(sessionStorage.getItem("loginToken"))?.idToken;
			onlineObj['OnlineStatus'] = true; 
			onlineObj['OnlineStatusID'] = onlineStatusID; 
			onlineObj['ReceiveQuickCall'] =  JSON.parse(profile?.ReceiveQuickCall); 
			onlineObj['ReceiveHireTutorCall'] =  JSON.parse(profile?.ReceiveHireTutorCall); 

			socket?.emit('PTModule', JSON.stringify(onlineObj))

			// dispatch(getTutorOnline({ OnlineStatus: true, OnlineStatusID: onlineStatusID, ReceiveQuickCall: Boolean(Number(receive_quick_call_val_as_hr)), ReceiveHireTutorCall: Boolean(Number(receive_pro_call_val_as_hr)) }));
			if (studentRequest.requestType.toString() === '1' && studentRequest?.IsDecline === true) {
				handleAudioPause();
				setHireTutorModal(false);
				setOpenCallCancelModal(true);
			}
		}
	
		function handleUserStateNotNull(studentRequest, responseDatahr) {
			if (responseDatahr.requestType == "1" && responseDatahr.TutorID == studentRequest.TutorID) {
				if (studentRequest.IsDecline == true || studentRequest.isPaymentDone.toString() === '3') {
					handleCallCancellation(studentRequest);
				} else if (studentRequest?.isPaymentDone.toString() === '2') {
					handleAgora(studentRequest);
				}
			}
		}
	
		function handleCallCancellation(studentRequest) {
			handleAudioPause();
			setHireTutorModal(false);
			setPaymentProcessModal(false);
			setOpenCallCancelModal(true);
			localStorage.removeItem("userState");
			sessionStorage.removeItem("TutorResponse");
			setHireCallingInfo(studentRequest);

			const onlineObj = {};
			onlineObj['EndPoint'] = '/TutorOnline';
			onlineObj['DeviceID'] = localStorage.getItem('deviceId');
			onlineObj['authorization'] = JSON.parse(sessionStorage.getItem("loginToken"))?.idToken;
			onlineObj['OnlineStatus'] = true; 
			onlineObj['OnlineStatusID'] = onlineStatusID; 
			onlineObj['ReceiveQuickCall'] =  JSON.parse(profile?.ReceiveQuickCall); 
			onlineObj['ReceiveHireTutorCall'] =  JSON.parse(profile?.ReceiveHireTutorCall); 

			socket?.emit('PTModule', JSON.stringify(onlineObj))

			// dispatch(getTutorOnline({ OnlineStatus: true, OnlineStatusID: onlineStatusID, ReceiveQuickCall: Boolean(Number(receive_quick_call_val_as_hr)), ReceiveHireTutorCall: Boolean(Number(receive_pro_call_val_as_hr)) }));
		}
	
		function handleAgora(studentRequest) {
			localStorage.removeItem('QCImage');
			sessionStorage.setItem('SessionID', studentRequest?.SessionID);
			const sessionID = sessionStorage.getItem('SessionID');
			handleAudioPause();
			const userUid = user?.uid;
			const channelName = `channel${sessionID}`;
			generateAgoraToken(userUid, 2, channelName).then((res) => {
				setPaymentProcessModal(false);
				localStorage.setItem("rtmToken", JSON.stringify(res.RtmToken));
				localStorage.setItem("rtcToken", JSON.stringify(res.RtcToken));
				navigate(`/hire-class-room/${encryptData(sessionID)}`);
			});
		}
	
		function handleAudioPlay() {
			if (!isAudioPlaying) {
				audio.loop = true;
				audio.play();
				setIsAudioPlaying(true);
			}
		}
	
		function handleAudioPause() {
			audio.pause();
			audio.currentTime = 0;
			setIsAudioPlaying(false);
		}
	};
	

	const onCallCancelModalClose = () => {
		setOpenCallCancelModal(false)
	}

	useEffect(() => {
		if (isOnline && isConnected) {
			socket?.emit("join", "QC");
		}
	}, [isOnline, isConnected]);

	const onAgora = (sessionId) => {
		setOnAgreeLoading(true)
		const userUid = user?.uid;
		const channelName = `channel${sessionId}`;
		localStorage.removeItem('QCImage');
		sessionStorage.setItem('SessionID', sessionId);
		const tutorRes = sessionStorage.getItem('TutorResponse');
		if (!tutorRes) {
			const tutorResponseData = {}
			tutorResponseData['TutorID'] = profileInfo?.ID;
			tutorResponseData['TutorName'] = profileInfo?.UserName;
			tutorResponseData['TutorImage'] = profileInfo?.ProfilePictureUrl;
			tutorResponseData['StudentID'] = tutorDashboard?.PrivateTutorInfo?.StudentID;
			tutorResponseData['StudentName'] = tutorDashboard?.PrivateTutorInfo?.StudentName;
			tutorResponseData['StudentImage'] = tutorDashboard?.PrivateTutorInfo?.StudentProfilePictureUrl;
			tutorResponseData['TutorPrice'] = tutorDashboard?.PrivateTutorInfo?.CallCost;
			// tutorResponse = tutorResponseData;
			sessionStorage.setItem('TutorResponse', JSON.stringify(tutorResponseData))
		}
		generateAgoraToken(userUid, 2, channelName).then((res) => {
			setPaymentProcessModal(false);
			localStorage.setItem("rtmToken", JSON.stringify(res.RtmToken));
			localStorage.setItem("rtcToken", JSON.stringify(res.RtcToken));
			navigate(`/hire-class-room/${encryptData(sessionId)}`);
		});
	}
	const onAgreeHireClickHandler = () => {
		audio.pause();
		audio.currentTime = 0;
		const payload = {};
		payload['DeviceID'] = localStorage.getItem('deviceId');
		payload['EndPoint'] = '/TutorRequest';
		payload['authorization'] = JSON.parse(sessionStorage.getItem('loginToken'))?.idToken;
		payload['StudentID'] = hireCallingInfo?.StudentID;
		payload['StudentName'] = hireCallingInfo?.StudentName;
		payload['StudentClass'] = hireCallingInfo?.StudentClass;
		payload['StudentImage'] = hireCallingInfo?.StudentImage;
		payload['TopicName'] = hireCallingInfo?.TopicName // send topic name 'English,Maths'
		payload['IsDecline'] = false;
		payload['IsAccept'] = true;
		payload['TutorID'] = hireCallingInfo?.TutorID
		payload['langid'] = localStorage.getItem('langId');
		payload['requestType'] = 2; // student = 1 tutor= 2
		payload['TutorName'] = hireCallingInfo?.TutorName;
		payload['TutorImage'] = hireCallingInfo?.TutorImage;
		payload['TutorRating'] = hireCallingInfo?.TutorRating;
		payload['Price'] = hireCallingInfo?.Price;
		payload['TutorPrice'] = hireCallingInfo?.TutorPrice;
		payload['CurrencyType'] = hireCallingInfo?.CurrencyType;
		payload['TutorTotalSesssion'] = hireCallingInfo?.TutorTotalSesssion;
		payload['isPaymentDone'] = hireCallingInfo?.isPaymentDone // default = 0  success =2  failed =3 // after payment
		payload['SessionID'] = hireCallingInfo?.isPaymentDone // default send 0 // after payment send session id
		socket.emit('PTModule', JSON.stringify(payload));
		setPaymentProcessModal(true);

	};

	const onDisAgreeHireClickHandler = () => {
		localStorage.removeItem("userState")
		sessionStorage.removeItem("TutorResponse")
		const payload = {};
		payload['DeviceID'] = localStorage.getItem('deviceId');
		payload['EndPoint'] = '/TutorRequest';
		payload['authorization'] = JSON.parse(sessionStorage.getItem('loginToken'))?.idToken;
		payload['StudentID'] = hireCallingInfo?.StudentID;
		payload['StudentName'] = hireCallingInfo?.StudentName;
		payload['StudentClass'] = hireCallingInfo?.StudentClass;
		payload['StudentImage'] = hireCallingInfo?.StudentImage;
		payload['TopicName'] = hireCallingInfo?.TopicName // send topic name 'English,Maths'
		payload['IsDecline'] = false;
		payload['IsAccept'] = false;
		payload['TutorID'] = hireCallingInfo?.TutorID
		payload['langid'] = localStorage.getItem('langId');
		payload['requestType'] = 2; // student = 1 tutor= 2
		payload['TutorName'] = hireCallingInfo?.TutorName;
		payload['TutorImage'] = hireCallingInfo?.TutorImage;
		payload['TutorRating'] = hireCallingInfo?.TutorRating;
		payload['TutorPrice'] = hireCallingInfo?.TutorPrice;
		payload['CurrencyType'] = hireCallingInfo?.CurrencyType;
		payload['TutorTotalSesssion'] = hireCallingInfo?.TutorTotalSesssion;
		payload['isPaymentDone'] = hireCallingInfo?.isPaymentDone // default = 0  success =2  failed =3 // after payment
		payload['SessionID'] = hireCallingInfo?.isPaymentDone // default send 0 // after payment send session id
		socket.emit('PTModule', JSON.stringify(payload));
		setHireTutorModal(false);
		// const onlineStatusID = 1; // 1 for online, 2 for busy & 3 for offline
		// dispatch(getTutorOnline({ OnlineStatus: true, OnlineStatusID: onlineStatusID, ReceiveQuickCall: receiveQuickCalls, ReceiveHireTutorCall: receiveProCalls }));

		const onlineObj = {};
		onlineObj['EndPoint'] = '/TutorOnline';
		onlineObj['DeviceID'] = localStorage.getItem('deviceId');
		onlineObj['authorization'] = JSON.parse(sessionStorage.getItem("loginToken"))?.idToken;
		onlineObj['OnlineStatus'] = true; 
		onlineObj['OnlineStatusID'] = 1; 
		onlineObj['ReceiveQuickCall'] =  receiveQuickCalls; 
		onlineObj['ReceiveHireTutorCall'] =  receiveProCalls; 

		socket?.emit('PTModule', JSON.stringify(onlineObj))
	}

	const checkOnlineStatus = () => {
		socket?.on('responseEvent', (data) => {
			const onlineStatusResponse =  JSON.parse(data);
			console.log('onlineStatusResponse: ', onlineStatusResponse);
			setTutorOnlineState(onlineStatusResponse.TutorOnline)
		})
	}

	useEffect(() => {
		isConnected && checkOnlineStatus();
		
	}, [isConnected, receiveQuickCalls, receiveQuickCalls])



	useEffect(() => {
		const handleOnline = async () => {
			setIsOffline(false);
			window.location.reload();	
		};

		const handleOffline = () => {
			setIsOffline(true);
			setReceiveProCalls(false);
			setReceiveQuickCalls(false);
			setTutorOnlineState((prev) => ({...prev, OnlineStatus: false}));
			socket.disconnect();
		};
	
		window.addEventListener('online', handleOnline);
		window.addEventListener('offline', handleOffline);
	
		return () => {
			const profile = JSON.parse(sessionStorage.getItem('ProfileInfo'));
			if (profile) {
				profile.OnlineStatus = false;
				profile.ReceiveQuickCall = false;
				profile.ReceiveHireTutorCall = false;
	
				sessionStorage.setItem('ProfileInfo', JSON.stringify(profile));
			}
	  
			const onlineObj = {};
			onlineObj['EndPoint'] = '/TutorOnline';
			onlineObj['DeviceID'] = localStorage.getItem('deviceId');
			onlineObj['authorization'] = JSON.parse(sessionStorage.getItem("loginToken"))?.idToken;
			onlineObj['OnlineStatus'] = false; 
			onlineObj['OnlineStatusID'] = 3; 
			onlineObj['ReceiveQuickCall'] =  false; 
			onlineObj['ReceiveHireTutorCall'] =  false; 
			
			socket?.emit('PTModule', JSON.stringify(onlineObj))

			window.addEventListener('online', handleOnline);
		  	window.removeEventListener('offline', handleOffline);
		};
	}, []);

	useEffect(() => {
		if (helperData) {
			setQuestionType(helperData?.QuestionType)
			const cancellationReasonArr = helperData?.CancellationReason.filter((item) => item?.RoleID === 2)
			setCancellationReason(cancellationReasonArr);
		}

	}, [helperData])

	useEffect(() => {
		if (translations?.length > 0) {
			setIsLoading(false);
		}
	}, [translations])

	if (isLoading) {
		return <Loading />
	}

	return (<>
		<ToastContainer
			position="top-right"
			autoClose={5000}
			hideProgressBar={false}
			newestOnTop={false}
			closeOnClick
			rtl={false}
			pauseOnFocusLoss
			draggable
			pauseOnHover
			theme="dark"
		/>
		<YasaHeader />
		<img src='../../images/cloud.png' alt='' className='cloud-img' />
		<div className="tutor-session-details">
			<div className="container">
				<div className="welcome-box-wrap">
					<Grid container spacing={2}>
						<Grid item xs={4} sm={4} md={4}>
							<div className='tutor-session-card'>
								{/* <div className="img-holder">
									<img src={profileInfo?.ProfilePictureUrl} alt="profile-pic" className='' />
								</div> */}
								<div className='teacher-info p-0'>
									<h2>{profileInfo?.UserName}</h2>
									{/* <p>{profileInfo?.Topics?.length} {translation?.Topic}</p> */}
									{/* <p>{profileInfo?.UserName}</p> */}
									
									{(tutorOnlineState?.OnlineStatus) ? <span className='inline-block my-5px tutor-status-btn'>{connected?.Online}</span> : <span className='inline-block my-5px tutor-disconnect-btn'>{connected?.Offline}</span>}
									{/* <h2>{profileInfo?.Topics?.length} {translation?.Topic}</h2> */}

								</div>
							</div>

						</Grid>
						{(tutorDashboard?.IsProfessional) ? <Grid item xs={8} sm={8} md={4}>
							<div className='online-action-box'>
								<Switch checked={receiveQuickCalls} onClick={handleReceiveQuickCalls} /><h2>{tutorDashboardLan?.ReceiveQuickCalls}</h2>
							</div>
						</Grid>
							: <Grid item xs={8} sm={8} md={8}>
								<div className='online-action-box'>
									<Switch checked={receiveQuickCalls} onClick={handleReceiveQuickCalls} /><h2>{tutorDashboardLan?.ReceiveQuickCalls}</h2>
								</div>
							</Grid>
						}
						{tutorDashboard?.IsProfessional && <Grid item xs={8} sm={8} md={4}>
							<div className='online-action-box'>
								<Switch checked={receiveProCalls} onClick={handleReceiveProCalls} /><h2> {tutorDashboardLan?.ReceiveProCalls}</h2>
							</div>

						</Grid>}
						{(tutorDashboard?.PrivateTutorInfo?.StudentName?.length > 2) &&
							<Grid item xs={12} sm={12} md={12}>
								<p>{tutorDashboard?.privateTutorInfo}</p>

								<div className="user-class-info-box">
									<div style={{ borderRadius: '50%', width: '80px', height: '80px' }}>
										<img
											src={tutorDashboard?.PrivateTutorInfo?.StudentProfilePictureUrl}
											// src="https://storage.googleapis.com/yasa_profile_image/17061818993304dGu4usZeWano0mktQB6W6cE8ot2.jpg"
											style={{ borderRadius: '50%', width: '80px', height: '80px', border: '1px solid #e0e0e0' }} />
									</div>
									<div style={{ padding: '0 15px' }}>
										<h3 className='title-info mb-1'>
											{/* THis is name */}
											{tutorDashboard?.PrivateTutorInfo?.StudentName}</h3>
										<Button
											sx={{ px: 2, py: 1, fontSize: '12px' }}
											variant='outlined' onClick={() => onAgora(tutorDashboard?.PrivateTutorInfo?.ID)}>{onAgreeLoading && <CircularProgress
												size={24}
												sx={{
													color: '#ffffff',
												}}
												className='ml-5'
											/>}{commonFrontEnd?.JoinSessions}</Button>
									</div>
								</div>
							</Grid>
						}
						<Grid item xs={12} sm={12} md={12}>
							<div className='teacher-overview-box'>
								<h1 className='title-1'>{translation?.OverviewLabel}</h1>
								<div className='flexrow'>
									{(tutorDashboard?.IsProfessional) ? <>

										<div className='col-4 mb-30'>
											<Link to="/session-history">
												<div className="overview-info-box box-yellow">

													<PlayCircleOutlinedIcon className='oib-icon clr-yellow' />
													<h3 className='title-info'>{commonFrontEnd?.PrivateSessionToday}</h3>
													<p>{tutorDashboard?.PrivateTutorToday}</p>
												</div>
											</Link>
										</div>
										<div className='col-4 mb-30'>
											<Link to="">
												<div className="overview-info-box box-red">
													<StarsOutlinedIcon className='oib-icon clr-red' />
													<h3 className='title-info'>{commonFrontEnd?.PrivateSessionRating}</h3>
													<p>{tutorDashboard?.PrivateTutorRating}</p>
												</div>
											</Link>
										</div>
									</> : ""}
									<div className='col-4 mb-30'>
										<Link to="/session-history">
											<div className="overview-info-box box-green">
												<VideocamOutlinedIcon className='oib-icon clr-green' />
												<h3 className='title-info'>{commonFrontEnd?.QuickCallSessionToday}</h3>
												<p>{tutorDashboard?.SessionsToday}</p>
											</div>
										</Link>
									</div>
									<div className='col-4 mb-30'>
										<Link to="/performance">
											<div className="overview-info-box box-red">
												<StarsOutlinedIcon className='oib-icon clr-red' />
												<h3 className='title-info'>{commonFrontEnd?.QuickCallSessionRating}</h3>
												<p>{tutorDashboard?.YourRatings}</p>
											</div>
										</Link>
									</div>
									<div className='col-4 mb-30'>
										<Link to="/payment">
											<div className="overview-info-box box-blue">
												<PaymentsOutlinedIcon className='oib-icon clr-blue' />

												<h3 className='title-info'>{commonFrontEnd?.TodayEarning}</h3>
												<p>{tutorDashboard?.TodayEarnings} {profileInfo?.CurrencyCode}</p>
											</div>
										</Link>
									</div>
								</div>
							</div>
						</Grid>
					</Grid>
				</div>

				{
					tutorDashboard && (
						tutorDashboard.AlertMsg !== '' && (
							<div className='alert-wrapper'>
								<Alert severity="warning" icon={<NotificationsNoneOutlinedIcon />}>
									<AlertTitle>{tutorDashboard.AlertMsg}</AlertTitle>
								</Alert>
							</div>
						)
					)
				}

				{
					tutorDashboard && tutorDashboard.SuggestionMsg?.Body !== '' && (
						<div className='alert-wrapper'>
							<Alert severity="info" icon={<MailOutlineIcon />}>
								<AlertTitle>{tutorDashboard.SuggestionMsg.Body}</AlertTitle>
							</Alert>
						</div>
					)
				}


			</div>
		</div>



		<Dialog
			open={callModal}
			keepMounted
			aria-describedby="alert-dialog-slide-description"
			className='cis-dialog-1 for-student'>

			<DialogContent>

				<Card className="card student-call-model" sx={{ margin: '0 auto' }}>
					<CardHeader
						avatar={
							<Avatar aria-label="recipe" sx={{ width: 60, height: 60 }}>
								<img src={callingStudentInfo?.StudentImage} />
							</Avatar>
						}
						title={callingStudentInfo?.StudentName}
						subheader={callingStudentInfo?.StudentClass} />

					<CardContent className="cardContent">
						<Typography gutterBottom variant="h5" component="h5" sx={{ fontSize: 18, mx: 0, mt: 0, textAlign: 'Left !important' }}>
							{i18n.language === 'en' ? callingStudentInfo?.EnglishMessage : callingStudentInfo?.ArabicMessage}
						</Typography>
						<div className='question-box'>
							{callingStudentInfo?.Notes?.length > 0 == false && <img src={callingStudentInfo?.ImageURL} alt='' className='question-img' />}
							<Typography gutterBottom variant="h5" component="h2" sx={{ fontSize: 18, mx: 0, mt: 2, textAlign: 'Left !important' }}>
								{callingStudentInfo?.Notes}
							</Typography>
						</div>
					</CardContent>
				</Card>
			</DialogContent>
			<DialogActions sx={{ justifyContent: 'center', gap: 2 }}>
				<Button className='quick-call-accept-btn' id='quick-call-accept-btn' onClick={onAgreeClickHandler} variant="contained" color='primary' size='large' sx={{ px: 5, lineHeight: 1.46 }}>{onAgreeQcLoading && <CircularProgress
					size={24}
					sx={{
						color: '#ffffff',
					}} />}{commonFrontEnd?.Accept}</Button>
				<Button onClick={onDisagreeClickHandler} variant="contained" color='error' size='large' sx={{ px: 5, }}>{commonFrontEnd?.Reject}</Button>
			</DialogActions>
		</Dialog>

		<Dialog
			open={openratingmodel}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogContent>
				<h2 className='title-1 text-center mb-0'>{commonFrontEnd?.SessionEndTeacher} {tutorResponse?.TutorName}</h2>
				<p className='title-info text-center'>{translation?.CallDurationLabel} : {sessionCost?.CallDuration} {translation?.Min}</p>
				{/* <p className='title-info text-center'>{translation?.CallDurationLabel} : {localStorage.getItem("CallDuration")} M</p> */}
				<p className='profitsession-info'>{translation?.ProfitSessionLabel} : <span>{sessionCost?.CallCost} {profileInfo?.CurrencyCode}</span></p>

				<div className='mb-3 ratinbox'>
					<p className='rating-label'>{commonFrontEnd?.CallExperience}</p>
					<div className="good-bad-rating" dir="ltr">
						<span className='rating_bad'>{translation?.Bad}</span>
						<Rating
							value={sessionRating}
							onChange={handleSessionRatingChange} />
						<span className='rating_good'>{translation?.Good}</span>

					</div>
				</div>
				<div className='mb-3 ratinbox'>
					<p className='rating-label'>{commonFrontEnd?.HowStudent}</p>
					<div className="good-bad-rating" dir="ltr">
						<span className='rating_bad'>{translation?.Bad}</span>
						<Rating
							value={studentRating}
							onChange={handleStudentRatingChange}  />
						<span className='rating_good'>{translation?.Good}</span>
					</div>
				</div>
				<div className='mb-3 ratinbox'>
					<p className='rating-label'>{commonFrontEnd?.RateQuestion}</p>
					<FormControl>

						<RadioGroup
							row
							aria-labelledby="demo-row-radio-buttons-group-label"
							value={selectedValue}
							onChange={handleChange}
						>
							{questionType?.map((question) => (
								<FormControlLabel
									key={question.ID}
									value={question.ID}
									control={<Radio />}
									label={question.Value}
								/>
							))}
						</RadioGroup>
					</FormControl>

				</div>
			</DialogContent>
			<DialogActions>
				<Button
					type="submit"
					variant="contained"
					sx={{ mt: 0, mb: 0, pt: 0.5, pb: 0.5, px: 2, fontSize: '18px', textTransform: "none" }}
					size="large"
					className='cis-btn m-0'
					onClick={onSaveFeedBackHandler}
					disabled={!tutorResponse?.QuestionID || !studentRating || !sessionRating || !selectedValue}
				>
					{translation?.QuickSubmit}
				</Button>
			</DialogActions>
		</Dialog>

		<Dialog
			open={openCallCancelModal}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogContent>
				<h2 className='title-1 text-center'>{commonFrontEnd?.StudentCancelRequest}</h2>
				<div className='text-center'>
					<Button onClick={onCallCancelModalClose} variant="contained" color='error' size='large' sx={{ px: 5 }}>Ok</Button>

				</div>
			</DialogContent>

		</Dialog>

		{/******Cancellation Prompt Box******/}
		<Dialog
			open={cancellationBox}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogContent>
				<p className='title-info text-center'>{translation?.AreYouSure}</p>
			</DialogContent>
			<DialogActions sx={{ justifyContent: 'center', gap: 2, pb: 3 }}>
				<Button onClick={onNoClickHandler}
					variant="outlined"
					sx={{ mt: 0, mb: 0, pt: 0.5, pb: 0.5, px: 2, width: '120px', fontSize: '18px', textTransform: "none" }}
				>{commonFrontEnd?.NoLabel}</Button>
				<Button
					type="submit"
					variant="contained"
					sx={{ mt: 0, mb: 0, pt: 0.5, pb: 0.5, px: 2, width: '120px', fontSize: '18px', textTransform: "none" }}
					size="large"
					className='cis-btn m-0'
					onClick={onYesClickHandler}
				>
					{commonFrontEnd?.YesLabel}
				</Button>
			</DialogActions>
		</Dialog>


		{/******Cancellation Reason Mode******/}
		<Dialog
			open={cancellationOpenModal}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogContent>
				<h2 className='title-1 text-center mb-0'>{translation?.ReasonForCancel}</h2>
				<div className='mb-3 ratinbox'>
					<FormControl>
						<RadioGroup
							row
							aria-labelledby="demo-row-radio-buttons-group-label"
							value={selectedReason}
							onChange={onCancellationHandleChange}
						>
							{cancellationReason?.map((reason) => (
								<FormControlLabel
									key={reason.ID}
									value={reason.ID}
									control={<Radio />}
									label={reason.Value}
								/>
							))}
						</RadioGroup>
					</FormControl>

				</div>
			</DialogContent>
			<DialogActions sx={{ justifyContent: 'center', pb: 3 }}>
				<Button
					type="submit"
					variant="contained"
					sx={{ mt: 0, mb: 0, pt: 0.5, pb: 0.5, px: 2, fontSize: '18px', textTransform: "none" }}
					size="large"
					className='cis-btn m-0'
					onClick={onSubmitCancellationReason}
				>
					{commonFrontEnd?.SubmitFeedBack}
				</Button>
			</DialogActions>
		</Dialog>

		{/*****Call Acceptance Modal*****/}
		<Dialog
			open={openCallAcceptanceModal}
			onClose={onDiscloseHandler}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogContent>
				<h2 className='title-1 text-center mb-0'>{translation?.CallAlreadyAccepted}</h2>
			</DialogContent>
			<DialogActions sx={{ justifyContent: 'center', pb: 3 }}>
				<Button
					type="submit"
					variant="contained"
					sx={{ mt: 0, mb: 0, pt: 0.5, pb: 0.5, px: 2, width: '120px', fontSize: '18px', textTransform: "none" }}
					size="large"
					className='cis-btn m-0'
					onClick={onDiscloseHandler}
				>
					{commonFrontEnd?.OkButton}
				</Button>
			</DialogActions>
		</Dialog>


		{/* Hire Tutor Accept Reject Dialoge Box */}
		<Dialog
			open={hireTutorModal}
			onClose={handleCloseHireModal}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogContent>
				<div className="tutor-session-card">
					<div className="img-holder">
						<img src={hireCallingInfo?.StudentImage} alt='' className='' />
					</div>
					<div className="teacher-info">
						<h2>{hireCallingInfo?.StudentName}</h2>
						<p>{hireCallingInfo?.StudentClass}</p>
					</div>
				</div>
				{/* <div className='subject_info'>
					<span>{hireCallingInfo?.SubjectName}</span>
				</div> */}
				<h2 className='title-1'>{hireCallingInfo?.Notes}</h2>
				<div className='img-holder'>
					<img alt='' src={hireCallingInfo?.ImageURL} />
				</div>
				<p className='title-info mt-4'>{commonFrontEnd?.ThisCallisFor} {hireCallingInfo?.StudentClass}, {hireCallingInfo?.SubjectName}</p>

			</DialogContent>
			<DialogActions sx={{ gap: 2 }}>
				<Button className='quick-call-accept-btn' id='quick-call-accept-btn' onClick={onAgreeHireClickHandler} variant="contained" color='primary' size='large' sx={{ px: 5, lineHeight: 1.46 }}>{commonFrontEnd?.Accept}</Button>
				<Button onClick={onDisAgreeHireClickHandler} variant="contained" color='error' size='large' sx={{ px: 5, }}>{commonFrontEnd?.Reject}</Button>
			</DialogActions>


		</Dialog>


		{/* Please Wait Payment Under Process */}
		<Dialog
			open={openPaymentProcessModal}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogContent>
				<h2 className='title-1 text-center mb-0'>{privateClassDashboard?.ConnectingWithStudent}.........</h2>
			</DialogContent>
		</Dialog>


		{/* Hire Tutor Feed Back Modal  */}
		<Dialog
			open={hireTutorRatingModal}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogContent>
				<h2 className='title-1 text-center'>{commonFrontEnd?.SessionEndTeacher} {tutorResponse?.TutorName}</h2>
				{/* <p className='title-info text-center'>{translation?.CallDurationLabel} : {localStorage.getItem('CallDuration')} {translation?.Min}</p> */}

				<p className='profitsession-info'>{translation?.ProfitSessionLabel} : <span>{`${tutorResponse?.TutorPrice} ${profileInfo?.CurrencyCode}`}</span></p>

				<div className='mb-3 ratinbox'>
					<p className='rating-label'>{commonFrontEnd?.CallExperience}</p>
					<div className="good-bad-rating" dir="ltr">
						<span className='rating_bad'>{translation?.Bad}</span>
						<Rating
							value={parseFloat(sessionRating)}
							onChange={handleSessionRatingChange} />
						<span className='rating_good'>{translation?.Good}</span>
					</div>
				</div>
				<div className='mb-3 ratinbox'>
					<p className='rating-label'>{commonFrontEnd?.HowStudent}</p>
					<div className="good-bad-rating" dir="ltr">
						<span className='rating_bad'>{translation?.Bad}</span>
						<Rating
							value={parseFloat(studentRating)}
							onChange={handleStudentRatingChange} />
						<span className='rating_good'>{translation?.Good}</span>
					</div>
				</div>
				<div className='mb-3 ratinbox'>
				</div>
			</DialogContent>
			<DialogActions>
				<Button
					type="submit"
					variant="contained"
					sx={{ mt: 0, mb: 0, pt: 0.5, pb: 0.5, px: 2, fontSize: '18px', textTransform: "none" }}
					size="large"
					className='cis-btn m-0'
					onClick={onSavePrivateTutorFeedBack}
					disabled={!studentRating || !sessionRating}
				>
					{translation?.QuickSubmit}
				</Button>
			</DialogActions>
		</Dialog>

		{/* Offline modal */}
		<Dialog
			open={isOffline}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogContent>
				<div className="offline-container">
					<h2 className='title-1 text-center mt-5'>{commonFrontEnd?.NetworkDisconnected}</h2>
					<div className="image-container">
						<img className='center-image' src="../../images/wifi-2.gif" alt="Network disconnected" />
					</div>
					<p className='title-info text-center'>{commonFrontEnd?.NetworkLost}</p>
				</div>
			</DialogContent>
		</Dialog>


		<img src='../../images/bottom-bg.png' alt='' className='cloud-img' />
	</>);
}

export default TutorQuickCall;