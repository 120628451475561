import * as actionTypes from "./actionTypes";
import * as apiCall from "../services/api";
import { API_BASE_URL } from "../config/apiConfig";

const updateTutorProfileSuccess = (data) => {
  return {
    type: actionTypes.UPDATE_TUTOR_PROFILE,
    payload: {
      profile: data.Data,
    },
  };
};

export const updateTutorProfile = (profile) => async (dispatch) => {
  return await apiCall
    .axiosUpdate(`${API_BASE_URL}/UpdateTutorDetails`, profile)
    .then((response) => {
      if (response) {
        dispatch(updateTutorProfileSuccess(response.data));
      }
    })
    .catch((error) => {
      console.log('Error: ', error);
    });
};