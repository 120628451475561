import React, { useEffect ,useState} from "react";
import { useNavigate } from "react-router-dom";
import useAgoraClassroom from "./useAgoraClassroom";
import { changeSessionStatus } from "../private-classes/PrivateClassCard";
import useTranslations from "../../custom-hooks/useTranslations";
 

const LaunchQuickCallRoom = () => {
  // debugger 
  // const[count , setCount]=useState(1)
  const translations = useTranslations();
  const navigate = useNavigate();
	const translation = translations[33]?.ApplicationConfiguration;
  const agoraCallDuration = translation?.AgoraCallDuration
  const sessionId = JSON.parse(sessionStorage.getItem('TutorResponse')).QuestionID;
  const TutorInfo = JSON.parse(sessionStorage.getItem('TutorResponse'));
  const profileInfo = JSON.parse(sessionStorage.getItem("ProfileInfo"));
  const userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
  const rtmToken = JSON.parse(localStorage.getItem("rtmToken"));
  const roomName = `room${TutorInfo?.QuestionID}`;
  const roomUuid = `roomuuid${TutorInfo?.QuestionID}`;
  const channel = `channel${TutorInfo?.QuestionID}`;
  const roomType = 0;
  const userUid = userInfo?.uid;
  const userName = profileInfo?.UserName;
  const pathName = "/receive-calls";  
  const flag = 'QuickCalls';
  const {
    agoraConfiguration,
    agoraSessionEnd,
  } = useAgoraClassroom({ roomUuid, userUid, rtmToken, roomName });
 

  useEffect(() => {
    const kiwiBig = document.querySelector("#kiwi-big-iframe-wrapper");
    if (kiwiBig) {
      kiwiBig.style.display = 'none';
    }
    const params = {
      userUid,
      userName,
      roomUuid,
      sessionId,
      roomName,
      channel,
      rtmToken,
      roomType,
      pathName,
      flag,
      agoraCallDuration,
      navigate,
      changeSessionStatus,
      agoraSessionEnd,
      agoraConfiguration,
    };
   
    // setTimeout(() => {
    //   setCount(2)
    // }, 1000);
 
    test(params);
  }, []);
  return;
  

};
  
export default LaunchQuickCallRoom;
