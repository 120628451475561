import React from 'react';
import './Loading.css';


const Loading = ({message}) => {
    return (
        <React.Fragment>
            <div className="loader-container">
                {/* <div className="loading load-3" > */}
                <div className='anim-logo'><img src='../../images/loader.gif' /></div>
                    {/* <p>{message}</p> */}
                    {/* <span className='loader-14'></span> */}
                    {/* <div className="line"></div>
                    <div className="line"></div>
                    <div className="line"></div> */}
                {/* </div> */}
            </div>
        </React.Fragment>
    )
}
export default Loading;