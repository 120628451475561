import * as actionTypes from "../actions/actionTypes";

const initialState = {};
const saveSessionRatingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SAVE_SESSION_RATINGS:
      return {
        sessionRatings: action.payload.sessionRatings,
      };

    default:
      return state;
  }
};
export default saveSessionRatingsReducer;
