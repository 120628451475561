import React, { useEffect, useState } from 'react';
import '../../Common.css';
import { FormControl, Select } from '@mui/material';
import { axiosPost } from '../../services/api';
import { Link, useNavigate } from 'react-router-dom';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import { Button } from '@mui/material';
import { Dialog, DialogContent, DialogActions } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import YasaHeader from '../common-components/YasaHeader';
import { useDispatch, useSelector } from 'react-redux';
import { getTutorTransaction } from '../../actions/getTutortransactionAction';
import {getTutorWithdraw} from '../../actions/getTutorWithdrawAction';
import useTranslations from '../../custom-hooks/useTranslations';

const PaymentPage = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [selectedValue, setSelectedValue] = useState(1);
	const [withdrawOpenModal, setWithdrawOpenModal] = useState(false);
	const user = JSON.parse(sessionStorage.getItem("userInfo"));
	const profileInfo = JSON.parse(sessionStorage.getItem("ProfileInfo"));
	const tutorTransaction = useSelector(({ getTutortransactionReducer }) => getTutortransactionReducer?.tutorTransaction);
	const translations = useTranslations();
	const wallet = translations[31]?.Wallet;
	const quickCall = translations[26]?.QuickCall;
	const commonFrontEnd = translations[21]?.CommonFrontend;
	const tutorDashboard = translations[28]?.TutorDashboard;
	const classDetail = translations[12]?.ClassDetail;
	const filterState = [
		{
			ID: 1,
			value: wallet?.Today
		},
		{
			ID: 2,
			value:wallet?.ThisWeek
		},
		{
			ID: 3,
			value: wallet?.LastWeek
		},
		{
			ID: 4,
			value: wallet?.ThisMonth
		},
		{
			ID: 5,
			value: wallet?.LastMonth
		},
		{
			ID: 6,
			value: wallet?.ThisYear
		},
		{
			ID: 7,
			value: wallet?.LastYear
		}
	
	];
	const handleClick = () => {
		setWithdrawOpenModal(true);
	};

	const onBackButtonHandler = () => {
		navigate(`/receive-calls`);
	};

	const handleSelectChange = (event) => {
		setSelectedValue(event.target.value)
		dispatch(getTutorTransaction({ FilterType: event.target.value }));
	};

	useEffect(() => {
		dispatch(getTutorTransaction({ FilterType: selectedValue }));
		dispatch(getTutorWithdraw());
	}, [dispatch])

	return (<>
		<YasaHeader />
		<img src='../../images/cloud.png' alt='' className='cloud-img' />
		<div className="container mb-5">
			<Button
				size="large"
				startIcon={<ArrowBackIcon className="backarrow" />}
				sx={{ px: 2, py: 1.25 }}
				className="back-btn"
				onClick={onBackButtonHandler}
			>
				{quickCall?.BackBtn}
			</Button>
		</div>

		<div className='tutor-performance-cards'>
			<div className='container'>
				<div className="welcome-box-wrap">
					<h1 className="title-1">{classDetail?.Payments}</h1>
					<div className="payment-box-wrap">
						<div className="pbw-col">
							<div className="payment-box">
								<h2>{tutorDashboard?.TotalEaring}</h2>
								<p> <span>{tutorTransaction?.RemainingAmount}</span>  {profileInfo?.CurrencyCode}</p>
							</div>
						</div>
						<div className="pbw-col">
							<div className="payment-box">
								<h2>{tutorDashboard?.WithdrawEaring}</h2>
								<p> <span>{tutorTransaction?.WithdrawEarningRounded}</span> {profileInfo?.CurrencyCode}</p>
							</div>
						</div>
						
					</div>

					<div className='withdraw-block'>
						{/* <Link to="/withdraw-payment"> */}
							<Button
								type="submit"
								variant="contained"
								sx={{ mt: 0, mb: 3, pt: 0.5, pb: 0.5, px: 2, fontSize: '18px', textTransform: "none" }}
								size="large"
								className='cis-btn'
								onClick={handleClick}
							>
								{commonFrontEnd?.WithdrawMoney}
							</Button>
						{/* </Link> */}
						{/* <p>{tutorDashboard?.PaymentSchedule} <br />
							<span className=''>{tutorDashboard?.BetweenDays}</span>
						</p> */}
					</div>

					<div className='payment-table'>
						<div className='payment-filter'>
							<FormControl>
								<Select
									value={selectedValue}
									sx={{ mt: 0, mb: 3, pt: 0, pb: 0, px: 2, fontSize: '16px', textTransform: "none" }}
									size='small'
									onChange={handleSelectChange}

								>
									{
										filterState?.map((days) => (
											<MenuItem value={days?.ID}  >{days.value}</MenuItem>
										))
									}
								</Select>
							</FormControl>
							<div className='payment-count'>
								{tutorTransaction?.FilteredEarning} {profileInfo?.CurrencyCode}
							</div>
						</div>
						<TableContainer component={Paper}>
							<Table sx={{ minWidth: 650 }} aria-label="simple table">
								<TableHead>
									<TableRow>
										<TableCell>{quickCall?.Date}</TableCell>
										<TableCell align="right">{quickCall?.CallCostLabel}</TableCell>
										<TableCell align="right">{quickCall?.TranstionType}</TableCell>
										<TableCell align="right">{commonFrontEnd?.Durations}</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{
										tutorTransaction?.SessionEarning?.map((earning, index) => (
											<TableRow
											sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
											key={index}
											>
												<TableCell component="th" scope="row">
													{new Date(earning?.CreatedDate).toLocaleString()}
												</TableCell>
												<TableCell align="right">{earning?.CallCost} {profileInfo?.CurrencyCode}</TableCell>
										        <TableCell align="right">{earning?.Type}</TableCell>
												<TableCell align="right">{earning?.CallDuration}</TableCell>
												<TableCell align="right"></TableCell>
											</TableRow>
										))
									}
								</TableBody>
							</Table>
						</TableContainer>
					</div>

				</div>
			</div>
		</div>

		<Dialog
			open={withdrawOpenModal}
			onClose={() => setWithdrawOpenModal(false)}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogContent>
				<h2 className='title-1 text-center mb-0'>{wallet?.WithdrawByBank}</h2>
			</DialogContent>
			<DialogActions sx={{ justifyContent: 'center', pb: 3 }}>
				<Button
					variant="contained"
					sx={{ mt: 0, mb: 0, pt: 0.5, pb: 0.5, px: 2, width: '120px', fontSize: '18px', textTransform: "none" }}
					size="large"
					className='cis-btn m-0'
					color="error"
					onClick={() => setWithdrawOpenModal(false)}
				>
					{commonFrontEnd?.OkButton}
				</Button>
			</DialogActions>
		</Dialog>


		<img src='../../images/bottom-bg.png' alt='' className='cloud-img' />
	</>);
}

export default PaymentPage;
