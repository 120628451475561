import * as actionTypes from "../actions/actionTypes";

const initialState = {};

const getTutorDashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_TUTOR_DASHBOARD:
      return {
        tutorDashboard: action.payload.tutorDashboard,
      };

    default:
      return state;
  }
};
export default getTutorDashboardReducer;

