import * as actionTypes from "../actions/actionTypes";

const initialState = {};

const getTutorFeeBackReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_TUTOR_DETAILED_FEEDBACK:
      return {
        tutorFeedBack: action.payload.tutorFeedBack,
      };

    default:
      return state;
  }
};
export default getTutorFeeBackReducer;

