import { createContext, useContext, useEffect, useState } from "react";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
  GoogleAuthProvider,
  signInWithPopup,
  RecaptchaVerifier,
  signInWithPhoneNumber,
  OAuthProvider,
  signInWithRedirect 
} from "firebase/auth";
import { auth } from "../firebase";

const userAuthContext = createContext();

export function UserAuthContextProvider({ children }) {
  const [user, setUser] = useState({});
  const [recaptcha, setRecaptcha] = useState();
  const [reCaptchaToken, setReCaptchaToken] = useState("");
  const [oneopt, setOneopt] = useState(true);
	const [secopt, setSecopt] = useState(true);
	const [thirdopt, setThirdopt] = useState(true);

  function logIn(email, password) {
    return signInWithEmailAndPassword(auth, email, password);
  }
  function signUp(email, password) {
    return createUserWithEmailAndPassword(auth, email, password);
  }
  function logOut() {
    sessionStorage.clear();
    return signOut(auth);
  }
  function googleSignIn() {
    const googleAuthProvider = new GoogleAuthProvider();
    return signInWithPopup(auth, googleAuthProvider);
  }

  function setUpRecaptcha() {
    const recaptchaVerifier = new RecaptchaVerifier(
      "recaptcha-container",
      {
        callback: (response) => {
          setReCaptchaToken(Boolean(response));
        },
        "expired-callback": () => {
          console.log("recaptcha expired");
          setReCaptchaToken("");
        },
      },
      auth
    );
    setRecaptcha(recaptchaVerifier);
    recaptchaVerifier.render();
  }

  function signInUsingPhoneNumber(number) {
    return signInWithPhoneNumber(auth, number, recaptcha);
  }

  function resendOTP(number) {
		if(oneopt){
		setRecaptcha("")
	    setOneopt(false);

		const recaptchaVerifier = new RecaptchaVerifier(
			"recaptcha-container1",
			{
				size: "invisible",
				callback: (response) => {
					console.log("recaptchaVerifier: ", recaptchaVerifier);
					return signInWithPhoneNumber(auth, number, recaptchaVerifier);
				},
				"expired-callback": () => {
					console.log("recaptcha expired");
					setReCaptchaToken("");
				},
			},
			auth
		);
		setRecaptcha(recaptchaVerifier);
		recaptchaVerifier.render().then((widgetId) => {
			console.log('widgetId: ', widgetId);
			recaptchaVerifier.recaptcha.execute(widgetId)
			setReCaptchaToken("");
		});
	   } else if (secopt){
		setSecopt(false)
		resendOTP2(number)
	   }
	   else if (thirdopt){
		setThirdopt(false)
		resendOTP3(number)
	   }
	   
	   else{
		resendOTP4(number)
	   }
		
	}
	function resendOTP2(number) {
		setRecaptcha("")
		const recaptchaVerifier = new RecaptchaVerifier(
			"recaptcha-container2",
			{
				size: "invisible",
				callback: (response) => {
					console.log("recaptchaVerifier: ", recaptchaVerifier);
					return signInWithPhoneNumber(auth, number, recaptchaVerifier);
				},
				"expired-callback": () => {
					console.log("recaptcha expired");
					setReCaptchaToken("");
				},
			},
			auth
		);
		setRecaptcha(recaptchaVerifier);
		recaptchaVerifier.render().then((widgetId) => {
			console.log('widgetId: ', widgetId);
			recaptchaVerifier.recaptcha.execute(widgetId)
			setReCaptchaToken("");
		});
		
	}
	function resendOTP3(number) {
		setRecaptcha("")
		const recaptchaVerifier = new RecaptchaVerifier(
			"recaptcha-container3",
			{
				size: "invisible",
				callback: (response) => {
					console.log("recaptchaVerifier: ", recaptchaVerifier);
					return signInWithPhoneNumber(auth, number, recaptchaVerifier);
				},
				"expired-callback": () => {
					console.log("recaptcha expired");
					setReCaptchaToken("");
				},
			},
			auth
		);
		setRecaptcha(recaptchaVerifier);
		recaptchaVerifier.render().then((widgetId) => {
			console.log('widgetId: ', widgetId);
			recaptchaVerifier.recaptcha.execute(widgetId)
			setReCaptchaToken("");
		});
		
	}
	function resendOTP4(){
		alert("Too many request")
		setTimeout(() => {
			window.location.reload();
			
		  }, 2000);
	}
	function appleSignIn() {
		const provider = new OAuthProvider('apple.com');
		// return signInWithPopup(auth, provider);
		signInWithPopup(auth, provider)
			.then((result) => {
				console.log('result: ', result);
				// The signed-in user info.
				const user = result.user;

				// Apple credential
				const credential = OAuthProvider.credentialFromResult(result);
				const accessToken = credential.accessToken;
				const idToken = credential.idToken;

				// IdP data available using getAdditionalUserInfo(result)
				// ...
			})
			.catch((error) => {
				console.log('error: ', error);
				// Handle Errors here.
				const errorCode = error.code;
				const errorMessage = error.message;
				// The email of the user's account used.
				const email = error.customData.email;
				// The credential that was used.
				const credential = OAuthProvider.credentialFromError(error);

				// ...
			})
	}


  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentuser) => {
      setUser(currentuser);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <userAuthContext.Provider
      value={{
        user,
        reCaptchaToken,
        logIn,
        signUp,
        logOut,
        googleSignIn,
        setUpRecaptcha,
        setReCaptchaToken,
        signInUsingPhoneNumber,
        resendOTP,
		appleSignIn
      }}
    >
      {children}
    </userAuthContext.Provider>
  );
}

export function useUserAuth() {
  return useContext(userAuthContext);
}
