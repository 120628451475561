import * as React from 'react';
import './PaymentHistory.css';
import { useState } from "react";
import Grid from '@mui/material/Grid';
import { FormControl, InputLabel, Link, Radio, RadioGroup, Select, TextField } from '@mui/material';
import SensorsIcon from '@mui/icons-material/Sensors';

import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import SearchIcon from '@mui/icons-material/Search';
import CircleIcon from '@mui/icons-material/Circle';
import InputAdornment from '@mui/material/InputAdornment';
import Rating from '@mui/material/Rating';


import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import CloseIcon from '@mui/icons-material/Close';
import TuneIcon from '@mui/icons-material/Tune';
import StarIcon from '@mui/icons-material/Star';




import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';

import Slider from '@mui/material/Slider';

import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import DialogContentText from '@mui/material/DialogContentText';
import CallMadeIcon from '@mui/icons-material/CallMade';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import Paper from '@mui/material/Paper';
import LogoutIcon from '@mui/icons-material/Logout';
import HistoryIcon from '@mui/icons-material/History';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

// Range Slieder 
function valuetext(value) {
  return `${value}°C`;
}
const minDistance = 10;



// success model 



function PaymentHistory() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const profileInfo = JSON.parse(sessionStorage.getItem("ProfileInfo"));

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // Model 
  const [modelopen, setModelOpen] = React.useState(false);
  const handleClickmodelOpen = () => {
    setModelOpen(true);
  };
  const modelhandleClose = () => {
    setModelOpen(false);
  };
  // Model2 
  const [modelopen2, setModelOpen2] = React.useState(false);
  const handleClickmodelOpen2 = () => {
    setModelOpen2(true);
  };
  const modelhandleClose2 = () => {
    setModelOpen2(false);
  };

  // Model3
  const [sessionEndModel, setSessionEndModel] = React.useState(false);
  const handleClicksessionEndModel = () => {
    setSessionEndModel(true);
  };
  const handleClicksessionEndModelClose = () => {
    setSessionEndModel(false);
  };

  const [age, setAge] = React.useState('');

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  return (
    <>

      <header className='mainHeader' component={Paper} elevation={6}>
        <div className='container'>
          <div className='row'>
            <div className='col-left'>
              <div>
                <img src='../../images/logo.png' alt='' className='' />
              </div>
            </div>
            <div className='col-right'>
              <div className=''>
                <div className='loginuser-menu' onClick={handleClick}>
                  <img src='../../images/user-img.png' alt='' className='' />
                </div>
                <Menu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                  className="loginuser-menu-wrap"
                >

                  <MenuItem disablePadding>
                    <ListItemIcon>
                      <LogoutIcon />
                    </ListItemIcon>
                    Logout
                  </MenuItem>
                  <MenuItem disablePadding>
                    <ListItemIcon>
                      <img src='../../images/icon/4.svg' alt='' />
                    </ListItemIcon>
                    Get YASAPRO
                  </MenuItem>
                </Menu>
              </div>
            </div>
          </div>
        </div>
      </header>

      <img src='../../images/cloud.png' alt='' className='cloud-img' />


      <div className='container tutor-search-result-page cis-rating-page payment-history-page'>
        <div className='welcome-box-wrap'>
          <div className='whitebox-wrap'>
            <div className='app-container'>
              <div className='total-earn-wrap'>
                <div className='total-earning-box Total-card'>
                  <h2 className='fs24 fw-600'>Total</h2>
                  <p>-297.85 <span>{profileInfo.CurrencyCode}</span></p>
                </div>
                <div className='total-earning-box Withdraw-card'>
                  <h2 className='fs24 fw-600'>Withdraw</h2>
                  <p>301.81 <span>{profileInfo.CurrencyCode}</span></p>
                </div>
              </div>
            
              <Button variant="contained" className='btn-1 btn-connect btn-withdraw' fullWidth sx={{ fontSize: 22, justifyContent: 'space-between' }}><span><HistoryIcon />Cash Withdrawal</span> <span><ChevronRightIcon /></span></Button>

              <div className='text-center mt-20 mb-20'>
                <p>Payment transfers are scheduled between <br /> 1st and 5th every month</p>
              </div>
              <div className='connect-to-tuter-sec'>
                <div className='app-container'>
                  {/* <div className='title-wrap'>
                    <h2 className='fs20'>Transaction Details</h2>
                  </div> */}
                  <div className='total-filter'>
                    <div className="flt-select">
                      <FormControl fullWidth>
                        <InputLabel id="date_label">Date</InputLabel>
                        <Select
                          labelId="date_label"
                          id="date_label-select"
                          value={age}
                          label="Age"
                          onChange={handleChange}
                        >
                          <MenuItem value={10}>Today</MenuItem>
                          <MenuItem value={20}>Yesterday</MenuItem>
                          <MenuItem value={30}>Thirty</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                    <div className="flt-amount">
                      <h3>0.00</h3>
                    </div>
                  </div>
                </div>
                <div className='app-container'>
                  <div className='transaction-card'>
                    <div className='tc-icon'>
                      <CallMadeIcon />
                    </div>
                    <div className='tc-content'>
                      <div className='tcc-left-wrap'>
                        <h3 className='fs20'>Erin Pacocha</h3>
                        <p className='tcc-info'><span className='tcci-box'>Hours :  1</span>  <span className='tcci-box'>Time :  2:30 PM, Today</span> <span className='tccr-rating'><StarIcon /> 4.6</span></p>
                      </div>
                      <div className='tcc-right-wrap'>
                        <h3 className='fs24 fw-700 color-2'>40KD</h3>
                      </div>
                    </div>
                  </div>

                  <div className='transaction-card'>
                    <div className='tc-icon'>
                      <CallMadeIcon />
                    </div>
                    <div className='tc-content'>
                      <div className='tcc-left-wrap'>
                        <h3 className='fs20'>Erin Pacocha</h3>
                        <p className='tcc-info'><span className='tcci-box'>Hours :  1</span>  <span className='tcci-box'>Time :  2:30 PM, Today</span> <span className='tccr-rating'><StarIcon /> 4.6</span></p>
                      </div>
                      <div className='tcc-right-wrap'>
                        <h3 className='fs24 fw-700 color-2'>40KD</h3>
                      </div>
                    </div>
                  </div>
                  <div className='transaction-card'>
                    <div className='tc-icon'>
                      <CallMadeIcon />
                    </div>
                    <div className='tc-content'>
                      <div className='tcc-left-wrap'>
                        <h3 className='fs20'>Erin Pacocha</h3>
                        <p className='tcc-info'><span className='tcci-box'>Hours :  1</span>  <span className='tcci-box'>Time :  2:30 PM, Today</span> <span className='tccr-rating'><StarIcon /> 4.6</span></p>
                      </div>
                      <div className='tcc-right-wrap'>
                        <h3 className='fs24 fw-700 color-2'>40KD</h3>
                      </div>
                    </div>
                  </div>
                  <div className='transaction-card'>
                    <div className='tc-icon'>
                      <CallMadeIcon />
                    </div>
                    <div className='tc-content'>
                      <div className='tcc-left-wrap'>
                        <h3 className='fs20'>Erin Pacocha</h3>
                        <p className='tcc-info'><span className='tcci-box'>Hours :  1</span>  <span className='tcci-box'>Time :  2:30 PM, Today</span> <span className='tccr-rating'><StarIcon /> 4.6</span></p>
                      </div>
                      <div className='tcc-right-wrap'>
                        <h3 className='fs24 fw-700 color-2'>40KD</h3>
                      </div>
                    </div>
                  </div>
                  <div className='transaction-card'>
                    <div className='tc-icon'>
                      <CallMadeIcon />
                    </div>
                    <div className='tc-content'>
                      <div className='tcc-left-wrap'>
                        <h3 className='fs20'>Erin Pacocha</h3>
                        <p className='tcc-info'><span className='tcci-box'>Hours :  1</span>  <span className='tcci-box'>Time :  2:30 PM, Today</span> <span className='tccr-rating'><StarIcon /> 4.6</span></p>
                      </div>
                      <div className='tcc-right-wrap'>
                        <h3 className='fs24 fw-700 color-2'>40KD</h3>
                      </div>
                    </div>
                    <div className='tc-bottom'>
                      <p>Time :  <span className='fw-600'>2:30 PM, Today</span> </p>
                      <p>Status : <span className='color-1 fw-600'>Paid</span> </p>
                    </div>
                  </div>
                  <div className='transaction-card'>
                    <div className='tc-icon'>
                      <CallMadeIcon />
                    </div>
                    <div className='tc-content'>
                      <div className='tcc-left-wrap'>
                        <h3 className='fs20'>Erin Pacocha</h3>
                        <p className='tcc-info'><span className='tcci-box'>Hours :  1</span>  <span className='tcci-box'>Time :  2:30 PM, Today</span> <span className='tccr-rating'><StarIcon /> 4.6</span></p>
                      </div>
                      <div className='tcc-right-wrap'>
                        <h3 className='fs24 fw-700 color-2'>40KD</h3>
                      </div>
                    </div>
                    <div className='tc-bottom'>
                      <p>Time :  <span className='fw-600'>2:30 PM, Today</span> </p>
                      <p>Status : <span className='color-1 fw-600'>Paid</span> </p>
                    </div>
                  </div>
                  <div className='transaction-card'>
                    <div className='tc-icon'>
                      <CallMadeIcon />
                    </div>
                    <div className='tc-content'>
                      <div className='tcc-left-wrap'>
                        <h3 className='fs20'>Erin Pacocha</h3>
                        <p className='tcc-info'><span className='tcci-box'>Hours :  1</span>  <span className='tcci-box'>Time :  2:30 PM, Today</span> <span className='tccr-rating'><StarIcon /> 4.6</span></p>
                      </div>
                      <div className='tcc-right-wrap'>
                        <h3 className='fs24 fw-700 color-2'>40KD</h3>
                      </div>
                    </div>
                    <div className='tc-bottom'>
                      <p>Time :  <span className='fw-600'>2:30 PM, Today</span> </p>
                      <p>Status : <span className='color-1 fw-600'>Paid</span> </p>
                    </div>
                  </div>


                  <Button variant="outlined" fullWidth sx={{ fontSize: 18 }}>Add Bank</Button>


                </div>
              </div>
            </div>
          </div>
        </div>


      </div>



    </>
  );
};

export default PaymentHistory;