import * as actionTypes from "./actionTypes";
import * as apiCall from "../services/api";
import { API_BASE_URL } from "../config/apiConfig";

const isObjectEmpty = (objectName) => {
  return Object.keys(objectName).length === 0
}

const withdrawPaymentsSuccess = (data) => {
  const noWithdrawPayments = isObjectEmpty(data.Data)
  return {
    type: actionTypes.WITHDRAW_PAYMENTS,
    payload: {
        withdraw: noWithdrawPayments ? [] : data.Data,
    },
  };
};

export const withdrawPayments = () => async (dispatch) => {
  return await apiCall
    .axiosGet(`${API_BASE_URL}/WithdrawRequest`)
    .then((response) => {
      if (response) {
        dispatch(withdrawPaymentsSuccess(response.data));
      }
    })
    .catch((error) => {
      console.log('Error: ', error);
    });
};