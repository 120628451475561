
import axios from 'axios';
import { API_BASE_URL } from '../config/apiConfig';

// Request interceptor to add headers to each request
axios.interceptors.request.use((config) => {
  const loginToken = JSON.parse(sessionStorage.getItem("loginToken"));
  config.headers["authorization"] = loginToken?.idToken;
  config.headers["AuthToken"] = localStorage.getItem("customToken");
  config.headers["deviceid"] = localStorage.getItem("deviceId");
  config.headers["langid"] = localStorage.getItem("langId") || 2;
  config.headers["Environment"] = localStorage.getItem("Environment") || 'Prod';
  config.headers["CountryID"] = localStorage.getItem("CountryID") || 113;
  config.headers["RoleID"] = '2';
  return config;
});

// Response interceptor to handle errors
axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response && error.response.status === 401) {
      const userInfo = JSON.parse(sessionStorage.getItem('loginToken') || '{}');
      if (userInfo) {
        try {
          // Attempt to refresh the token
          const response = await axiosGet(`${API_BASE_URL}/CustomToken`);
          console.log('Api failed & update token : ', response);
          
          const payload = {
            idToken: response?.data?.Token,
            expiresIn: userInfo?.expiresIn,
            localId: userInfo?.localId,
            isNewUser: userInfo?.isNewUser,
            phoneNumber: userInfo?.phoneNumber,
            expireTime: Date.now()
          };
          sessionStorage.setItem('loginToken', JSON.stringify(payload));

          // Update the request with the new token and retry
          error.config.headers["authorization"] = response?.data?.Token;
          return axios(error.config);
        } catch (error) {
          console.log('error: ', error);
        }
      } else {
        sessionStorage.clear();
        window.location.href = "/login";
      }
    }
    return Promise.reject(error);
  }
);

export const axiosGet = (url) => axios.get(url);
export const axiosPost = (url, data) => axios.post(url, data);
export const axiosUpdate = (url, data) => axios.patch(url, data);
