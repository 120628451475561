import React, { useEffect, useState } from "react";
import {
  Alert,
  Button,
  FormControl,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";
import { useSelector } from "react-redux";

const LoginContent = ({
  // flag,
  error,
  getOtpFunc,
  isApiCalling,
  buttonLable,
}) => {
  // Get all the country codes for phone
  const [number, setNumber] = useState("");
  const [country, setCountry] = useState({});
  const [countryCode, setCountryCode] = useState("");
  const [dialCode, setDialCode] = useState([]);
  
  const helperData = useSelector(({ helperDataReducer }) => helperDataReducer?.helperData);

  const handleFlagChange = (event) => {
    const selectedCountry = dialCode.find((item) => item?.DialCode === event.target.value)
    if (selectedCountry) {
      setCountry(selectedCountry);
      setCountryCode(event.target.value);
      setNumber((prev) => prev.slice(0, selectedCountry.NumbersLimit))
    }
  };

  const handleChange = (e) => {
    const inputValue = e.target.value;
    if (/^[0-9]*$/.test(inputValue) && inputValue.length <= country.NumbersLimit) {
      setNumber(inputValue);
    }
  };

  useEffect(() => {
    if (helperData) {
      setDialCode(helperData.Country || []);
      const selectedCountry = helperData?.Country.filter((countryItem) => countryItem?.ID.toString() === localStorage.getItem('CountryID'))
      if (selectedCountry.length > 0) {
        setCountry(selectedCountry[0])
        setCountryCode(selectedCountry[0]?.DialCode)
      } else {
        setCountryCode("+965")
        setCountryCode({ NumbersLimit : 8})
      }
    }
    
  }, [helperData])

  return (
    <>
      <div className="box">
        {error && <Alert severity="error">{error}</Alert>}
        {/* <form style={{ display: !flag ? "block" : "none", direction:"ltr"}}> */}
          <FormControl
            variant="outlined"
            fullWidth
            className="cis-enter-number"
            style={{direction:"ltr"}}
          >
            <OutlinedInput
              id="standard-adornment-amount"
              value={number}
              onChange={handleChange}
              minLength={2}
              startAdornment={
                <InputAdornment position="start">
                <Select
                  className="cis-country-code"
                  value={countryCode}
                  onChange={handleFlagChange}
                >
                  {dialCode?.map((code) => (
                    <MenuItem key={code?.ID} value={code?.DialCode}>{code?.DialCode}</MenuItem>
                  ))}
                </Select>
              </InputAdornment>
              }
              label=""
              sx={{
                input: {
                  color: "#999999",
                  "&::placeholder": {
                    // <----- Add this.
                    opacity: 1,
                  },
                },
              }}
            />
          </FormControl>
          <Button
            // type="submit"
            fullWidth
            variant="contained"
            sx={{
              mt: 3,
              mb: 3,
              pt: 1.5,
              pb: 1.5,
              fontSize: "20px",
              textTransform: "none",
            }}
            size="large"
            className="cis-btn"
            disabled={number.trim().length !== country?.NumbersLimit || isApiCalling}
            onClick={() => {
              const phoneNumber = countryCode + number.trim();
              getOtpFunc(phoneNumber)
            }}
          >
            {buttonLable}
          </Button>
        {/* </form> */}
      </div>
    </>
  );
};

export default LoginContent;
