import React, { useEffect, useState } from 'react';
import '../../Common.css';
import { useNavigate } from 'react-router-dom';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import { Button } from '@mui/material';
import { StarRateSharp } from '@mui/icons-material';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import PaymentsOutlinedIcon from "@mui/icons-material/PaymentsOutlined";
import YasaHeader from '../common-components/YasaHeader';
import { useDispatch, useSelector } from 'react-redux';
import { getSessionToday } from '../../actions/getSessionTodayAction';
import useTranslations from '../../custom-hooks/useTranslations';
import timeAgoDate from '../../services/timeAgoDate';
import momentDate from '../../services/momentDate';


const SessionHistory = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const sessionToday = useSelector(({ getSessionTodayReducer }) => getSessionTodayReducer?.sessionToday);
	const translations = useTranslations();
	const quickCall = translations[26]?.QuickCall;
	
	useEffect(() => {
		dispatch(getSessionToday());
	}, [dispatch]);


	const onBackButtonHandler = () => {
		navigate(`/receive-calls`);
	};

	return (<>
		<YasaHeader />
		<img src='../../images/cloud.png' alt='' className='cloud-img' />
		<div className="container mb-5">
			<Button
				size="large"
				startIcon={<ArrowBackIcon className="backarrow" />}
				sx={{ px: 2, py: 1.25 }}
				className="back-btn"
				onClick={onBackButtonHandler}
			>
				{quickCall?.BackBtn}
			</Button>
		</div>

		<div className='tutor-performance-cards'>
			<div className='container'>
				<div className="welcome-box-wrap">
					{
						sessionToday ? (
							sessionToday?.map((session, index) => (
								<div className='tutor-performance-card' key={index}>
									<div className='tpc-head d-flex'>
										<ul className='icon-list-1'>
											<li>
												
												<CalendarTodayOutlinedIcon /> {momentDate(session?.Date)}
											</li>
											<li>
												{/* <AccessTimeOutlinedIcon /> {new Date(session?.CreatedDate).toLocaleTimeString('en-US')} */}
												<AccessTimeOutlinedIcon /> {timeAgoDate(session?.CreatedDate)}
											</li>
										</ul>
										<span className='end-box star-icon-box'>
											{session?.Rating}<StarRateSharp />
										</span>
									</div>
									<div className='tpc-inner-cols'>
										<div className="tpc-col-12">
											<div className='tpc-earnings'>
												<p className='tpc-earning'><PaymentsOutlinedIcon /> {session?.CallCost !== "NaN" ? session?.CallCost : 0} </p>
												{/* <div className='tpc-subject-type'>
													<span>{session?.TopicName}</span>
												</div> */}
											</div>

											<div className='file-placeholder'>
												<video src={session?.RecordedSessionURL} controls></video>
											</div>
										</div>
									</div>
									<div className='tpc-head'>
										<a className='icon-list-1' href={session?.SessionNotesUrl} target="_blank">
											<ArticleOutlinedIcon />
											<span className="px-2" style={{color: 'black'}}> {quickCall?.NotesLabel}</span>
										</a>
										{/* <ul className='icon-list-1'>
											<li>
												<ArticleOutlinedIcon /> {session?.Notes}
											</li>
										</ul> */}
									</div>

								</div>
							))
						) : (
							<div className="wrapper">
								<div className="loader"></div>
							</div>)
					}
				</div>
			</div>
		</div>
		<img src='../../images/bottom-bg.png' alt='' className='cloud-img' />
	</>);
}

export default SessionHistory;