import * as actionTypes from "../actions/actionTypes";

const initialState = {};
const getTutortransactionReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_TUTOR_TRANSACTION:
      return {
        tutorTransaction: action.payload.tutorTransaction,
      };

    default:
      return state;
  }
};
export default getTutortransactionReducer;
