
//************ API URL ***************************************************
export const API_BASE_URL = 'https://us-central1-yasa-21022022.cloudfunctions.net'; // Production Base URL  



//************ WebSocket URL ***************************************************
export const WebSocketURL = 'https://yasa-21022022.uc.r.appspot.com/'; // Production Socket URL 
// export const WebSocketURL = "https://development-dot-yasa-21022022.uc.r.appspot.com" // Development Socket URL
// export const WebSocketURL = "https://stagging-dot-yasa-21022022.uc.r.appspot.com/";
// export const WebSocketURL = "https://driving-beagle-trivially.ngrok-free.app"
