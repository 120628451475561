import React, { useState, useEffect, useParams } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { API_BASE_URL } from "../../../config/apiConfig";
import { axiosPost } from "../../../services/api";
import { ChatDisplay } from "../chat/Events";

const NotesSection = (prop) => {
  const { sessionData, translations } = prop;
  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  const [value, setValue] = useState(0);
  const changeTabs = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Grid item xs={12} sm={12} md={12} className="cis-tabs">
      <Box sx={{ width: "100%" }}>
        <Box sx={{}}>
          <Tabs
            value={value}
            onChange={changeTabs}
            aria-label="basic tabs example"
            className="cis-class-details-tabs"
          >
            <Tab label={translations?.Notes} {...a11yProps(0)} />
            <Tab label={translations?.ChatHistory} {...a11yProps(1)} />
            
          </Tabs>
        </Box>

        <TabPanel value={value} index={0}>
          <NotesTab sessionId={sessionData?.ID} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <ChatHistoryTab sessionId={sessionData?.ID} />
        </TabPanel>
        
      </Box>
    </Grid>
  );
};
export default NotesSection;

const NotesTab = ({ sessionId }) => {
  const [sessionNotes, setSessionNotes] = useState([]);
  const fetchSessionNotes = () => {
    const data = {
      SessionID: sessionId,
    };
    axiosPost(`${API_BASE_URL}/GetSessionNotes`, data).then((res) => {
      if (res.data.Message === "User is not validated") {
        sessionStorage.removeItem("loginToken");
        sessionStorage.removeItem("userInfo");
        window.location.href = "/login";
      } else setSessionNotes(res.data.Data);
    });
  };

  useEffect(() => {
    fetchSessionNotes();
  }, []);

  const style = {
    height: "150px",
    width: "auto",
    objectFit: "contain",
  };

  return sessionNotes?.map((sessionNote) => (
    <a href={sessionNote.SessionNotesUrl} download="session-note" target="_blank">
      <img
        src="https://community.adobe.com/legacyfs/online/1475181_PDF%20-%20Large.png"
        style={style}
        alt="pdf"
      />
    </a>
  ));
};

const ChatHistoryTab = ({ sessionId }) => {
  const [chatEvents, setChatEvents] = useState([]);
  const userUid = JSON.parse(sessionStorage.getItem("userInfo"))?.uid;
  const fetchChatBackup = () => {
    const data = {
      ClassID: `S${sessionId}`,
    };
    axiosPost(`${API_BASE_URL}/GetChatdata`, data).then((res) => {
      if (res.data.Message === "User is not validated") {
        sessionStorage.removeItem("loginToken");
        sessionStorage.removeItem("userInfo");
        window.location.href = "/login";
      } else setChatEvents(res.data.Data);
    });
  };
  useEffect(() => {
    fetchChatBackup();
    const chatScreen = document.getElementById("chatScreen");
    chatScreen.scrollTo(0, chatScreen.scrollHeight);
  }, []);

  return (
    <>
      <div className="cis-chat-card">
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <div id="chatScreen" className="cis-chat">
              {chatEvents?.map((event) => (
                <ChatDisplay userUid={userUid} event={event} />
              ))}
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
};
