import React, { useEffect, useState } from "react";

import "./schedule.css";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import YasaHeader from "../common-components/YasaHeader";
import YasaContainer from "../common-components/YasaContainer";
import SessionList from "./schedule/SessionList";
import TestScreen from "./test-screen/TestScreen";
import PackageList from "./payment/PackageList";
import DocumentScreen from "./document/DocumentScreen";
import Chat from "./chat/Chat";
import Overview from "./overview/OverView";
import useTranslations from "../../custom-hooks/useTranslations";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const ClassDetailMain = (props) => {
  const translations = useTranslations();
  const translation = translations[12]?.ClassDetail;
  const commonTranslation = translations[21]?.CommonFrontend;
  const tutorRegistrationTrans = translations[23]?.TutorRegistration;
  const completeTrans = translations[18]?.YourCourses;
  const classDeatilTranslations = {
    ...translation,
    ...commonTranslation,
    ...completeTrans,
    ...tutorRegistrationTrans,
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  const [value, setValue] = useState(0);
  const handleChange2 = (event, newValue) => {
    setValue(newValue);
  };
  useEffect(() => {
    if (props.index) setValue(2);
  }, []);
  const messageTeacher = () => {
    setValue(2);
  };

  const navigate = useNavigate();

  const handleBack = (e) => {
    e.preventDefault();
    navigate("/tutor-private-classes");
  };

  return (
    <>
      <YasaHeader />
      <img src="../../images/cloud.png" alt="" className="cloud-img" />
      <div className="container mb-5">
        <Button
          size="large"
          startIcon={<ArrowBackIcon className="backarrow" />}
          sx={{ px: 2, py: 1.25 }}
          onClick={handleBack}
          className="back-btn"
        >
          {translations[8]?.PrivateClassDashboard.Back}
        </Button>
      </div>
      <YasaContainer>
        <div className="whitebox-wrap cis-schedule-cards pt-0">
          <Grid item xs={12} sm={12} md={12} className="cis-tabs">
            <Box sx={{ width: "100%" }}>
              <Box sx={{}}>
                <Tabs
                  value={value}
                  onChange={handleChange2}
                  aria-label="basic tabs example"
                  className="cis-class-details-tabs"
                >
                  <Tab
                    label={classDeatilTranslations?.Overview}
                    {...a11yProps(0)}
                  />
                  <Tab
                    label={classDeatilTranslations?.Schedule}
                    {...a11yProps(1)}
                  />
                  {/* <Tab label="Tests" {...a11yProps(2)} /> */}
                  {/* <Tab
                    label={classDeatilTranslations?.Documents}
                    {...a11yProps(2)}
                  /> */}
                  <Tab
                    label={classDeatilTranslations?.Chats}
                    {...a11yProps(2)}
                  />
                  {/* <Tab
                    label={classDeatilTranslations?.Payments}
                    {...a11yProps(3)}
                  /> */}
                </Tabs>
              </Box>
              <TabPanel value={value} index={0}>
                <Overview
                  messageTeacher={messageTeacher}
                  AskYourQuestion={classDeatilTranslations?.AskYourQuestion}
                  MinTenChar={classDeatilTranslations?.MinTenChar}
                />
              </TabPanel>

              <TabPanel value={value} index={1}>
                <SessionList
                  classDeatilTranslations={classDeatilTranslations}
                />
              </TabPanel>

              {/* <TabPanel value={value} index={2}>
                <TestScreen />
              </TabPanel> */}

              {/* <TabPanel value={value} index={2}>
                <DocumentScreen />
              </TabPanel> */}

              <TabPanel value={value} index={2}>
                <Chat />
              </TabPanel>

              {/* <TabPanel value={value} index={3}>
                <PackageList />
              </TabPanel> */}
            </Box>
          </Grid>
        </div>
      </YasaContainer>
      <img src="../../images/bottom-bg.png" alt="" className="cloud-img" />
    </>
  );
};

export default ClassDetailMain;
