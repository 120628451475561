import { API_BASE_URL } from "../../config/apiConfig";
import { axiosPost } from "../../services/api";
import { agoraConfig } from "./agoraConfig";


const useAgoraClassroom = ({ roomUuid, userUid, rtmToken, roomName }) => {
	const { appId, region } = agoraConfig;
	const config = {
		headers: {
			"x-agora-token": rtmToken,
			"x-agora-uid": userUid,
		},
	};

	const agoraConfiguration = async (userName, roomType, sessionId ) => {
		const whiteBoardConfig = JSON.parse(localStorage.getItem('whiteBoardConfig') || '{}');
		const data = {
		  ChannelName: `channel${sessionId}`,
		  UserUID: userUid,
		  RoomUUID: roomUuid,
		  UserName: userName,
		  RoomType: roomType,
		  RTMToken: rtmToken,
		  SessionType: roomType === 0 ? "QC" : "PT",
		  SessionID: sessionId,
		  WhiteBoardToken: whiteBoardConfig?.roomToken,
		  WhiteBoardID: whiteBoardConfig?.roomId
		}

		const res = await axiosPost(`${API_BASE_URL}/AgoraConfiguration`, data);
		return res.data.URL;
	}

	function convertCallDurationToSeconds(callDuration) {
		// Split the call duration string into minutes and seconds
		const [minutes, seconds] = callDuration.split(' : ').map(num => parseInt(num));
	
		// Convert minutes to seconds and add with the seconds
		const totalSeconds = minutes * 60 + seconds;
	
		return totalSeconds;
	}

	

	const agoraSessionEnd = async (roomType, sessionId, flag, pathName, navigate) => {
		const callDuration = localStorage.getItem('CallDuration');
		const callDurationSeconds = convertCallDurationToSeconds(callDuration);
		const data = {
		  SessionType: roomType === 0 ? "QC" : "PT",
		  CallDuration: callDurationSeconds.toString(),
		  SessionID: sessionId,
		 
		}

		if (flag !== 'PrivateClass' && flag !== 'HireTutor') {
			const response = await axiosPost(`${API_BASE_URL}/GetSessionCost`, {SessionID: sessionId, CallDuration: callDurationSeconds});
			if (response.data) {
			    sessionStorage.setItem('SessionCost', JSON.stringify(response.data.Data));
			}
			sessionStorage.setItem('SessionEnd', true)
		}

		try {
			const res = await axiosPost(`${API_BASE_URL}/AgoraSessionEnd`, data);
			window.location.pathname = pathName;
		  } catch (e) {
			window.location.pathname = pathName;
		}
	}

	return {
		agoraConfiguration,
		agoraSessionEnd
	};
};
export default useAgoraClassroom;
