import React from "react";
import Paper from "@mui/material/Paper";
import { useDispatch, useSelector } from 'react-redux';
import LogoutIcon from "@mui/icons-material/Logout";
import { Button, ListItemIcon, Menu, MenuItem } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { signOut } from "firebase/auth";
import { auth } from "../../firebase";
import useSocket from "../../custom-hooks/useSocket";
import useTranslations from "../../custom-hooks/useTranslations";
import { getTutorOnline } from "../../actions/tutorOnlineStatus";
import './YasaHeader.css'

const YasaHeader = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { socket } = useSocket();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const translations = useTranslations();
  const translation = translations[3]?.MenuScreen;
  const private_class_name = translations[21]?.CommonFrontend;
  const receive_calls_name = translations[20]?.WebDashboard ;


  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    signOut(auth);
    socket?.disconnect();
    sessionStorage.clear();
    navigate("/login");
  };

  const onLogoClickHandler = () => {
    navigate("/");
  }

  // const userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
  const profileInfo = JSON.parse(sessionStorage.getItem("ProfileInfo"));
  return (
    <header className="mainHeader" component={Paper} elevation={6}>
      <div className="container">
        <div className="row">
          <div className="col-left">
            <div>
              <img src="../../images/logo.png" alt="" onClick={onLogoClickHandler} className="" />
            </div>
          </div>
          <div className="col-right">
            <div className="header-options"> 
            {(window.location.pathname === "/receive-calls") ? "" :<Button variant="contained" onClick={()=>navigate("/receive-calls")}>{receive_calls_name?.ReceiveCalls }</Button>}
            {(window.location.pathname === "/private-classes") ? "" :<Button variant="contained" onClick={()=>navigate("/private-classes")}>{private_class_name?.TutorPrivateClasses }</Button>}
              <div className="loginuser-menu" onClick={handleClick}>
                <img
                  src={profileInfo?.ProfilePictureUrl}
                  height={70} width={71}
                  alt="profile-pic"
                  className=""
                />
              </div>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                className="loginuser-menu-wrap"
              >
                <MenuItem disablepadding="true" onClick={handleLogout}>
                  <ListItemIcon>
                    <LogoutIcon />
                  </ListItemIcon>
                  {translation?.Logout}
                </MenuItem>
                {/* <MenuItem disablePadding>
                  <ListItemIcon>
                    <img src="../../images/icon/4.svg" alt="" />
                  </ListItemIcon>
                  Get YASAPRO
                </MenuItem> */}
              </Menu>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default YasaHeader;
