import React, { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { changeSessionStatus } from "../private-classes/PrivateClassCard";
import useAgoraClassroom from "./useAgoraClassroom";
import { decryptData } from "../../encryptDecrypt";
import useTranslations from "../../custom-hooks/useTranslations";

const LaunchClassRoom = () => {
  const translations = useTranslations();
  const navigate = useNavigate();
	const translation = translations[33]?.ApplicationConfiguration;
  const agoraCallDuration = translation?.AgoraCallDuration
  const { encryptClassId, encryptSessionId } = useParams();
  const classId = decryptData(encryptClassId)?.replace(/['"]+/g, "");
  const sessionId = decryptData(encryptSessionId);
  const roomName = `channel${classId}${sessionId}`;
  const roomUuid = `room${classId}${sessionId}`;
  const userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
  const profileInfo = JSON.parse(sessionStorage.getItem("ProfileInfo"));
  const rtmToken = JSON.parse(localStorage.getItem("rtmToken"));
  const userUid = userInfo?.uid;
  const userName = profileInfo?.UserName;
  const roomType = 4;
  const pathName = "/private-classes";
  const flag = 'PrivateClass';
  const {
    agoraConfiguration,
    agoraSessionEnd,
  } = useAgoraClassroom({ roomUuid, userUid, rtmToken, roomName });

  useEffect(() => {
    const kiwiBig = document.querySelector("#kiwi-big-iframe-wrapper");
    if (kiwiBig) {
      kiwiBig.style.display = 'none';
    }
    const params = {
      userUid,
      userName,
      roomUuid,
      roomName,
      rtmToken,
      sessionId,
      roomType,
      pathName,
      flag,
      agoraCallDuration,
      navigate,
      changeSessionStatus,
      agoraSessionEnd,
      agoraConfiguration
    };
    test(params);
  }, []);
  return;
};
export default LaunchClassRoom;
