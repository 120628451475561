import * as actionTypes from "../actions/actionTypes";

const initialState = {};
const receiveQuickCallReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.RECEIVE_QUICK_CALL:
      return {
        receiveQuickCall: action.payload.receiveQuickCall,
      };

    default:
      return state;
  }
};
export default receiveQuickCallReducer;
