import { combineReducers } from "redux";
import loginReducer from "./loginReducer.js";
import classReducer from "./classReducer.js";
import modalReducer from "./modalReducer";
import getTutorProfileReducer from './getTutorProfileReducer.js';
import onlineTutorReducer from './onlineTutorReducer';
import saveSessionStartTimeReducer from './saveSessionStartTimeReducer';
import getSessionCostReducer from './getSessionCostReducer';
import getQuestionTypeReducer from './getQuestionTypeReducer';
import saveSessionRatingsReducer from './saveSessionRatingReducer'; 
import getTutorDashboardReducer from './getTutordashboardReducer';
import receiveQuickCallReducer from './receiveQuickCallReducer';
import getCancellationReasonReducer from './getCancellationReasonReducer';
import rejectQuickCallReducer from './rejectQuickCallReducer';
import getTutorFeeBackReducer from './getTutorFeedBackReducer';
import getSessionTodayReducer from './getSessionTodayReducer';
import getTutortransactionReducer from './getTutorTransactionReducer';
import getTutorWithdrawReducer from './getTutorWithdrawReducer';
import withdrawPaymentReducer from './withdrawPaymentReducer';
import getTranslationsReducer from "./getTranslationReducer.js";
import getCountryCodeReducer from "./getCountryCodeReducer.js";
import helperDataReducer from "./getHelperDataReducer.js";
import examBoardsReducer from "./getExamBoardsReducer.js";
import updateTutorProfileReducer from "./updateTutorProfileReducer.js";

export default combineReducers({
  loginReducer,
  classReducer,
  modalReducer,
  getTutorProfileReducer,
  onlineTutorReducer,
  saveSessionStartTimeReducer,
  getSessionCostReducer,
  getQuestionTypeReducer,
  saveSessionRatingsReducer,
  getTutorDashboardReducer,
  receiveQuickCallReducer,
  getCancellationReasonReducer,
  rejectQuickCallReducer,
  getTutorFeeBackReducer,
  getSessionTodayReducer,
  getTutortransactionReducer,
  getTutorWithdrawReducer,
  withdrawPaymentReducer,
  getTranslationsReducer,
  getCountryCodeReducer,
  helperDataReducer,
  examBoardsReducer,
  updateTutorProfileReducer
});
