import * as actionTypes from "./actionTypes";
import * as apiCall from "../services/api";
import { API_BASE_URL } from "../config/apiConfig";

const saveSessionStartTimeSuccess = (data) => {
  return {
    type: actionTypes.SAVE_SESSION_START_TIME,
    payload: {
      savedSession: data?.IsSuccess,
    },
  };
};

export const saveSessionStartTime = (payload) => async (dispatch) => {
  return await apiCall
    .axiosPost(`${API_BASE_URL}/SaveSessionStartTime`, payload)
    .then((response) => {
      if (response) {
        dispatch(saveSessionStartTimeSuccess(response.data));
      }
    })
    .catch((error) => {
      console.log('Error: ', error?.response?.data);
      if (error?.response?.data?.StatusCode === 400) {
        dispatch(saveSessionStartTimeSuccess(error?.response?.data))
      }
    });
};