import * as actionTypes from "../actions/actionTypes";

const initialState = {};

const getTutorProfileReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_TUTOR_PROFILE:
      return {
        profile: action.payload.profile,
      };

    default:
      return state;
  }
};
export default getTutorProfileReducer;

