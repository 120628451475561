import React, { useEffect, useState } from 'react';
import './Performance.css';
import '../../Common.css';
import { Link, useParams, useNavigate } from 'react-router-dom';
import LibraryAddCheckOutlinedIcon from '@mui/icons-material/LibraryAddCheckOutlined';
import PhotoFilterOutlinedIcon from '@mui/icons-material/PhotoFilterOutlined';
import StarHalfOutlinedIcon from '@mui/icons-material/StarHalfOutlined';
import EventAvailableOutlinedIcon from '@mui/icons-material/EventAvailableOutlined';
import { Button } from '@mui/material';
import Grid from '@mui/material/Grid';
import Rating from '@mui/material/Rating';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useDispatch, useSelector } from 'react-redux';
import { getTutorDetailedFeedBack } from '../../actions/getTutorFeedBackAction';
import YasaHeader from '../common-components/YasaHeader';
import useTranslations from '../../custom-hooks/useTranslations';



const Performance = () => {
	const dispatch = useDispatch();
	const params = useParams();
	const navigate = useNavigate();
	const user = JSON.parse(sessionStorage.getItem("userInfo"));
	const [isQCRating, setIsQCRating] = useState(params?.callType === 'quick-call' ? true : false);
	const [qCRating, setQCRating] = useState({});
	const [hCRating, setHCRating] = useState({});
	const translations = useTranslations();
	const translation = translations[26]?.QuickCall;
	const commonFrontEnd = translations[21]?.CommonFrontend;
	const tutorDashboard = translations[28]?.TutorDashboard;
	
	const tutorFeedBack = useSelector(({ getTutorFeeBackReducer }) => getTutorFeeBackReducer?.tutorFeedBack);

	const handleClick = (value) => {
		if (value) {
			setIsQCRating(true);
			navigate(`/performance/quick-call`)
		} else {
			setIsQCRating(false);
			navigate(`/performance/hire-call`)
		}
	}

	useEffect(()=> {
		if (tutorFeedBack) {
			const {HireTutorReport, ...QuickTutorReport} = tutorFeedBack
			setQCRating(QuickTutorReport);
			setHCRating(HireTutorReport);
		}

	},[tutorFeedBack])

	useEffect(()=> {
		dispatch(getTutorDetailedFeedBack());
	}, [dispatch]);

	return (<>
		<YasaHeader />
		<img src='../../images/cloud.png' alt='' className='cloud-img' />
		<div className="container mb-5">
			<Button
				size="large"
				startIcon={<ArrowBackIcon className="backarrow" />}
				sx={{ px: 2, py: 1.25 }}
				className="back-btn"
				component={Link}
				to='/receive-calls'
			>
				{translation?.BackBtn}
			</Button>
		</div>

		<div className="mb-20 call-btn">
			<div className="call-check-radio-wrapper">
			<label className="call-check-radio-box">
				<input type="radio" name="call-check-radio-box" value="true" checked={isQCRating === true} onChange={() => handleClick(true)} />
				<span className="check_bx">
					{commonFrontEnd?.QuickCallBtn}
				</span>
			</label>
			<label className="call-check-radio-box">
				<input type="radio" name="call-check-radio-box" value="false" checked={isQCRating === false} onChange={() => handleClick(false)} />
				<span className="check_bx">
					{commonFrontEnd?.HireCallBtn}
				</span>
			</label>
			</div>
		</div> 

		{
			isQCRating === false ?
			<div className="tutor-performance-details mb-30">
				<div className="container">
					<div className="welcome-box-wrap">
						<Grid container spacing={2}>
							<Grid item xs={12} sm={12} md={12}>

								<div className="performance-rating-box">
									<div>
										<div className='prb-value text-center'>
											<span>{hCRating?.TotalRatings || 0}</span>
										</div>
										<div className='pr-rating-wrap'>
											<span className="prb-tag"> {commonFrontEnd?.RatingBad}</span>
											<Rating name="half-rating" value={parseFloat(hCRating?.TotalRatings) || 0} precision={0.5} className='mx-4' readOnly />
											<span className="prb-tag"> {commonFrontEnd?.RatingExcellent}</span>
										</div>
									</div>

								</div>


							</Grid>

							<Grid item xs={12} sm={12} md={12}>
								<div className='teacher-overview-box'>
									<div className='flexrow'>
										<div className='col-4'>
											<div className="overview-info-box box-yellow">
												<PhotoFilterOutlinedIcon className='oib-icon clr-yellow' />
												<h3 className='title-info'>{tutorDashboard?.RatingLast15}</h3>
												<p>{hCRating?.RatingFifteenDays || 0}</p>
											</div>
										</div>
										<div className='col-4'>
											<div className="overview-info-box box-green">
												<LibraryAddCheckOutlinedIcon className='oib-icon clr-green no-scale' />
												<h3 className='title-info'>{tutorDashboard?.AcceptanceRate}</h3>
												<p>{hCRating?.AcceptanceRate || 0}</p>
											</div>
										</div>
										<div className='col-4'>
											<div className="overview-info-box box-blue">
												<StarHalfOutlinedIcon className='oib-icon clr-blue' />
												<h3 className='title-info'>{tutorDashboard?.Star5RatedSession}</h3>
												<p>{hCRating?.FiveStarRated || 0}</p>
											</div>
										</div>
										<div className='col-4'>
											<div className="overview-info-box box-red">
												<StarHalfOutlinedIcon className='oib-icon clr-red' />
												<h3 className='title-info'>{tutorDashboard?.PoorlyRatedSession}</h3>
												<p>{hCRating?.PoorlyRated || 0}</p>
											</div>
										</div>
									</div>
								</div>
							</Grid>
						</Grid>
					</div>
				</div>
			</div> : <></>
		}

		{
			isQCRating === true ?
			<React.Fragment>
				<div className="tutor-performance-details mb-30">
					<div className="container">
						<div className="welcome-box-wrap">
							<Grid container spacing={2}>
								<Grid item xs={12} sm={12} md={12}>

									<div className="performance-rating-box">
										<div>
											<div className='prb-value text-center'>
												<span>{qCRating?.TotalRatings || 0}</span>
											</div>
											<div className='pr-rating-wrap'>
												<span className="prb-tag"> {commonFrontEnd?.RatingBad}</span>
												<Rating name="half-rating" value={parseInt(qCRating?.TotalRatings) || 0} precision={0.5} className='mx-4' readOnly />
												<span className="prb-tag"> {commonFrontEnd?.RatingExcellent}</span>
											</div>
										</div>

									</div>


								</Grid>

								<Grid item xs={12} sm={12} md={12}>
									<div className='teacher-overview-box'>
										<div className='flexrow'>
											<div className='col-4'>
												<div className="overview-info-box box-yellow">
													<PhotoFilterOutlinedIcon className='oib-icon clr-yellow' />
													<h3 className='title-info'>{tutorDashboard?.RatingLast15 || 0}</h3>
													<p>{qCRating?.RatingFifteenDays}</p>
												</div>
											</div>
											<div className='col-4'>
												<div className="overview-info-box box-green">
													<LibraryAddCheckOutlinedIcon className='oib-icon clr-green no-scale' />
													<h3 className='title-info'>{tutorDashboard?.AcceptanceRate || 0}</h3>
													<p>{qCRating?.AcceptanceRate}</p>
												</div>
											</div>
											<div className='col-4'>
												<div className="overview-info-box box-blue">
													<StarHalfOutlinedIcon className='oib-icon clr-blue' />
													<h3 className='title-info'>{tutorDashboard?.Star5RatedSession || 0}</h3>
													<p>{qCRating?.FiveStarRated}</p>
												</div>
											</div>
											<div className='col-4'>
												<div className="overview-info-box box-red">
													<StarHalfOutlinedIcon className='oib-icon clr-red' />
													<h3 className='title-info'>{tutorDashboard?.PoorlyRatedSession || 0}</h3>
													<p>{qCRating?.PoorlyRated}</p>
												</div>
											</div>
										</div>
									</div>
								</Grid>
							</Grid>
						</div>
					</div>
				</div>

				<div className='tutor-performance-cards'>
					<div className='container'>
						<h1 className="title-1">{tutorDashboard?.SubjectWiseFeedback}</h1>
						<div className="welcome-box-wrap">
							{
								qCRating?.TopicRating?.map((performance, index)=> (
								<div className='tutor-performance-card' key={index}>
								<div className='tpc-head'><h4>{performance?.SubjectName}</h4></div>
								<div className='tpc-inner-cols'>
									<div className="tpc-col">
										<p>{tutorDashboard?.Session15Days}</p>
										<p className='tpc-data clr-red'>{performance?.RatingFifteenDays || 0}</p>
									</div>
									<div className="tpc-col">
										<p>{tutorDashboard?.AcceptanceRate}</p>
										<p className='tpc-data clr-blue'>{performance?.AcceptanceRate}%</p>
									</div>
									<div className="tpc-col">
										<p>{tutorDashboard?.Ratings}</p>
										<p className='tpc-data'>{performance?.Ratings}</p>
									</div>
								</div>
								<div className='tpc-head'><h4><EventAvailableOutlinedIcon /> {tutorDashboard?.NotesToday}</h4></div>
								<div className='tpc-inner-cols'>
									<div className="tpc-col">
										<p>{tutorDashboard?.PoorlyRatedSession}</p>
										<p className='tpc-data'>{performance?.PoorlyRated}</p>
									</div>
									<div className="tpc-col">
										<p>{tutorDashboard?.Star5RatedSession}</p>
										<p className='tpc-data'>{performance?.FiveStarRated}</p>
									</div>
								</div>
							</div>
							))	
							}
						</div>
					</div>
				</div>
			</React.Fragment> : <></>
		}



		<img src='../../images/bottom-bg.png' alt='' className='cloud-img' />
	</>);
}

export default Performance;
