import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useAgoraClassroom from "./useAgoraClassroom";
import { changeSessionStatus } from "../private-classes/PrivateClassCard";
import useTranslations from "../../custom-hooks/useTranslations";


const LaunchHireTutorClassRoom = () => {
  // const[count , setCount]=useState(1)
  const translations = useTranslations();
  const navigate = useNavigate();
	const translation = translations[33]?.ApplicationConfiguration;
  const agoraCallDuration = translation?.AgoraCallDuration
  const sessionId = sessionStorage.getItem('SessionID');
  const userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
  const profileInfo = JSON.parse(sessionStorage.getItem("ProfileInfo"));
  const rtmToken = JSON.parse(localStorage.getItem("rtmToken"));
  const roomName = `privateroom${sessionId}`;
  const roomUuid = `privateroomuuid${sessionId}`;
  const channel = `channel${sessionId}`;
  const roomType = 4;
  const userUid = userInfo?.uid;
  const userName = profileInfo?.UserName;
  const pathName = "/receive-calls";
  const flag = 'HireTutor'; 
  const {
    agoraConfiguration,
    agoraSessionEnd,
  } = useAgoraClassroom({ roomUuid, userUid, rtmToken, roomName });


  useEffect(() => {
    // document.querySelector("#kiwi-big-iframe-wrapper").style.display = 'none'
    const kiwiBig = document.querySelector("#kiwi-big-iframe-wrapper");
    if (kiwiBig) {
      kiwiBig.style.display = 'none';
    }
    const params = {
      userUid,
      userName,
      roomUuid,
      sessionId,
      roomName,
      channel,
      rtmToken,
      roomType,
      pathName,
      flag,
      agoraCallDuration,
      navigate,
      changeSessionStatus,
      agoraSessionEnd,
      agoraConfiguration,
    };
    
    // setTimeout(() => {
    //   setCount(2)
    // }, 1000);
 
    test(params); 
  }, []);
  return;
};
export default LaunchHireTutorClassRoom;
