import React from "react";
import { Button, Card, CardContent, Chip, Grid } from "@mui/material";
import { Box } from "@mui/system";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";

const SessionDetailCard = (props) => {
  const { sessionData, translations } = props;
  return (
    <div className="cis-schedule-card">
      <Grid container spacing={2}>
        <Grid item xs={12} md={8}>
          <Card sx={{ display: "flex" }} className="cis-card">
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <CardContent sx={{ flex: "1 1 auto" }}>
                <h2 className="cis-title-1">{sessionData?.Value}</h2>

                <div className="cis-Chips-2">
                  {/* <Chip
                    icon={<WatchLaterIcon />}
                    variant="outlined"
                    label="10 mins ago"
                  /> */}
                  <Chip
                    icon={<CalendarTodayIcon />}
                    variant="outlined"
                    label={`Starts on ${sessionData?.ClassesDate}`}
                  />
                </div>
                {/* <h3 className="cis-title-3">Circles</h3>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Praesent aliquam mauris et rutrum dapibus. Proin a magna
                  felis.
                </p> */}
              </CardContent>
            </Box>
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box className="cis-teacher-card">
            <div className="cis-teacher-info">
              <div className="cis-content">
                <Button
                  variant="contained"
                  sx={{ px: 2 }}
                  size="small"
                  className="cis-complete-btn"
                >
                  {translations[sessionData?.SessionStatus]}
                </Button>
              </div>
            </div>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default SessionDetailCard;
