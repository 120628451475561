import React, { useEffect, useState } from 'react';
import '../../Common.css';
import { FormControl, Select } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import ListItemIcon from '@mui/material/ListItemIcon';
import Paper from '@mui/material/Paper';
import LogoutIcon from '@mui/icons-material/Logout';
import { Button } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useDispatch, useSelector } from 'react-redux';
import { getTutorWithdraw } from '../../actions/getTutorWithdrawAction';
import YasaHeader from '../common-components/YasaHeader';
import { withdrawPayments } from '../../actions/withdrawPaymentAction';
import useTranslations from '../../custom-hooks/useTranslations';
// import getTutorProfileReducer from './getTutorProfileReducer';
import { openModal } from "../../actions/modalAction";
import { ErrorModal } from '../private-classes/PrivateClass';


const WithdrawPaymentPage = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [withdraw, setWithdraw] = useState([])
	const user = JSON.parse(sessionStorage.getItem("userInfo"));
	const tutorWithdraw = useSelector(({ getTutorWithdrawReducer }) => getTutorWithdrawReducer?.withdraw);
	const withdrawPayment = useSelector(({ withdrawPaymentReducer }) => withdrawPaymentReducer?.withdraw);
	const translations = useTranslations();
	const quickCall = translations[26]?.QuickCall;
	const commonFrontEnd = translations[21]?.CommonFrontend;
	const tutorDashboard = translations[28]?.TutorDashboard;
	const payment = translations[13]?.Payment;
	const profileInfo = JSON.parse(sessionStorage.getItem("ProfileInfo"));
	const isOpenModal = useSelector(({ modalReducer }) => modalReducer.openModal);
	const translation = translations[0]?.Login

	const onWithdrawClickHandler = () => {
		if(profileInfo?.isBankDetailsAvailable){
			dispatch(withdrawPayments());
		}
		else{
			dispatch(openModal(payment?.BankDetailsMsg));
		}
	}

	useEffect(() => {
		dispatch(getTutorWithdraw());
	}, [dispatch]);


	useEffect(() => {
		setWithdraw(tutorWithdraw);
	}, [tutorWithdraw]);


	useEffect(() => {
		setWithdraw(withdrawPayment);
	}, [withdrawPayment]);


	return (<>
		<YasaHeader />
		<img src='../../images/cloud.png' alt='' className='cloud-img' />
		<div className="container mb-5">
			<Button
				size="large"
				startIcon={<ArrowBackIcon className="backarrow" />}
				sx={{ px: 2, py: 1.25 }}
				className="back-btn"
				component={Link}
				to='/payment'
			>
				{quickCall?.BackBtn}
			</Button>
		</div>

		<div className='tutor-performance-cards'>
			<div className='container'>
				<div className="welcome-box-wrap">
					{
						withdraw !== undefined && withdraw.length !== 0 ? (
							<React.Fragment>
								<div className='withdraw-block'>

									<h2 className='title-1 text-center'>{tutorDashboard?.LifetimeEarning}</h2>
									<p className='withdrawl-info'>{withdraw?.TotalEarning} {profileInfo?.CurrencyCode}</p>
									{(withdraw?.TotalEarning > parseFloat(withdraw.WithdrawLimit) && withdraw?.WithdrawEnable) && <Button
										type="submit"
										variant="contained"
										sx={{ mt: 0, mb: 3, pt: 0.5, pb: 0.5, px: 2, fontSize: '18px', textTransform: "none" }}
										size="large"
										className='cis-btn'
										onClick={onWithdrawClickHandler}
									>
										{tutorDashboard?.WithdrawNow}
									</Button>}
								</div>

								<div className='payment-table'>
									<TableContainer component={Paper}>
										<Table sx={{ minWidth: 650 }} aria-label="simple table">
											<TableHead>
												<TableRow>
													<TableCell>{quickCall?.Date}</TableCell>
													<TableCell align="right">{quickCall?.StatusLabel}</TableCell>
													{/* <TableCell align="right">Transtion type</TableCell> */}
													<TableCell align="right">{payment?.PayAmt}</TableCell>

												</TableRow>
											</TableHead>
											<TableBody>
												{
													withdraw?.WithdrawHistory?.map((item) => (
														<TableRow
															sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
														>
															<TableCell component="th" scope="row">
																{item?.WithdrawDate}
															</TableCell>
															<TableCell align="right">
																{item?.Status === 'Transferred' && <span className="status-info status-active">{item?.Status} </span>}
																{item?.Status === 'Requested' && <span className="status-info status-cancelled">{item?.Status} </span>}
																{/* <span class="status-info status-completed"> {item?.Status}</span> */}
																{/* <span class="status-info status-cancelled"> {item?.Status}  </span> */}
															</TableCell>
															<TableCell align="right">{item?.WithdrawAmount} {profileInfo?.CurrencyCode}</TableCell>
														</TableRow>
													))
												}

											</TableBody>
										</Table>
									</TableContainer>
								</div>
							</React.Fragment>

						) : (
							<div className="wrapper">
								<div className="loader"></div>
							</div>
						)
					}


				</div>
			</div>
		</div>


		<img src='../../images/bottom-bg.png' alt='' className='cloud-img' />
		{isOpenModal && <ErrorModal />}
	</>);
}

export default WithdrawPaymentPage;
