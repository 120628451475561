import { List, ListItem } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import HomePageContainer from "./HomePageContainer";
import useTranslations from "../../custom-hooks/useTranslations";
import { useDispatch, useSelector } from "react-redux";
import { getTutorDashboard } from "../../actions/getTutorDashboardAction";
import { useState } from "react";
import Loading from "../loader/Loading";


const SelectStudyList = [
	{
		icon: "../../images/icon/1.svg",
		heading: "Receive Calls",
		description: "Lorem ipsum dolor sit amet, conetur adiping elit.",
		link: "receive-calls"
	},
	{
		icon: "../../images/icon/2.svg",
		heading: "Tutor Private Classes",
		description: "Lorem ipsum dolor sit amet, conetur adiping elit.",
		link: "tutor-private-classes"
	},
];

const SelectStudy = () => {
	const dispatch = useDispatch();
	const translations = useTranslations();
	const translation = translations[20]?.WebDashboard;
	const commonTranslation = translations[21]?.CommonFrontend
	const studyTranslations = { ...translation, ...commonTranslation }
	const [isLoading, setIsLoading] = useState(true);

	const tutorProfile = useSelector(({ getTutorProfileReducer }) => getTutorProfileReducer.profile) || JSON.parse(sessionStorage.getItem('ProfileInfo'));

	useEffect(() => {
		dispatch(getTutorDashboard());
	}, [dispatch]);


	useEffect(() => {
		if (translations && tutorProfile) {
			setIsLoading(false)
		}
	}, [translations, tutorProfile])

	if (isLoading) {
		return <Loading message={'Please wait...'}/>
	}

	return (
		<React.Fragment>
			<HomePageContainer>
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
					}}
				>
					<Box component="form" noValidate sx={{ mt: 1 }}>
						<div className="mb-20">
							<h2 className="title2">{studyTranslations?.TutorHeading}</h2>
						</div>

						<div className="mb-20">
							<List
								sx={{ width: "100%", bgcolor: "background.paper" }}
								className="loginselectList"
							>
								{SelectStudyList?.map((item) => (
									<SelectStudyListItem
										key={item.heading}
										listItem={item}
										studyTranslations={studyTranslations}
									/>
								))}
							</List>
						</div>
					</Box>
				</Box>
			</HomePageContainer>

		</React.Fragment>

	);
};

export default SelectStudy;

const SelectStudyListItem = ({ listItem, studyTranslations }) => {
	// const route = listItem?.heading?.toLowerCase().split(" ").join("-");
	const navigate = useNavigate();
	const redirect = (link) => {
		navigate(`/${link}`);
	};

	const listItemHeading = convertPascalCase(listItem?.heading);
	return (
		<>
			{studyTranslations !== undefined && (
				<ListItem
					alignItems="flex-start"
					className="activeList"
					onClick={() => redirect(listItem?.link)}
				>
					<span className="lsl-icon">
						<img src={listItem.icon} alt="" />
					</span>
					<div className="lsl-content">
						{/* <h2>{studyTranslations[capitalizeFirstLetter(listItem?.heading)]}</h2> */}
						<h2>{studyTranslations[listItemHeading]}</h2>
						<p>{studyTranslations[`ShortDesc${listItemHeading}`]}</p>
					</div>
				</ListItem>
			)}
		</>
	);
};

export const convertPascalCase = (string) => {
	const splitedWord = string?.split(" ");
	if (splitedWord?.length === 1) {
		return string;
	} else {
		return splitedWord?.join("");
	}
};
