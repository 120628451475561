import React, { useEffect, useState } from "react";
import "./private-class.css";

import { Button, FormControlLabel, Switch } from "@mui/material";
import Grid from "@mui/material/Grid";

import YasaContainer from "../common-components/YasaContainer";
import YasaHeader from "../common-components/YasaHeader";
import ClassDetailCard from "./ClassDetailsCard";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { useDispatch, useSelector } from "react-redux";
import { getClasses } from "../../actions/classesAction";
import { closeModal } from "../../actions/modalAction";
import useTranslations from "../../custom-hooks/useTranslations";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { encryptData } from "../../encryptDecrypt";

const PrivateClass = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const translations = useTranslations();
  const yourCourseTrans = translations[18]?.YourCourses;
  const translation = {
    ...translations[8]?.PrivateClassDashboard,
    ...yourCourseTrans,
  };

  const classes = useSelector(({ classReducer }) => classReducer.classes);
  const [filteredClasses, setFilteredClasses] = useState();
  const [isActive, setIsActive] = useState();
  const [activeClasses, setActiveClasses] = useState(true);

  const helperData = useSelector(({ helperDataReducer }) => helperDataReducer?.helperData);

  const showActiveClasses = () => {
    if (activeClasses) {
      const active = !classes?.some(({ ClassStatus }) => ClassStatus === 'Active');
      setActiveClasses(false);
      const completeC = classes?.filter(({ ClassStatus }) => ClassStatus === 'Complete');
      setIsActive(active);
      setFilteredClasses(completeC);
    } else {
      setActiveClasses(true);
      const active = classes?.some(({ ClassStatus }) => ClassStatus === 'Active');
      const activeC = classes?.filter(({ ClassStatus }) => ClassStatus === 'Active');
      setIsActive(active);
      setFilteredClasses(activeC);
    }
  };

  const showClassDetails = (classId) => {
    navigate(`/class-details/${classId}`);
  };

  const showChatTab = (classId) => {
    navigate(`/class-details/${encryptData(classId)}/chat`);
  };

  const handleBack = (e) => {
    e.preventDefault();
    navigate("/");
  };

  useEffect(() => {
    dispatch(getClasses());
  }, [dispatch]);

  useEffect(() => {
    showActiveClasses();
  }, [classes]);

  return (
    <>
      <YasaHeader />
      <img src="../../images/cloud.png" alt="" className="cloud-img" />
      <div className="container mb-5">
        <Button
          size="large"
          startIcon={<ArrowBackIcon className="backarrow" />}
          sx={{ px: 2, py: 1.25 }}
          onClick={handleBack}
          className="back-btn"
        >
          {translation?.Back}
        </Button>
      </div>
      <YasaContainer xs={12} md={12}>
        <div className="whitebox-wrap cis-tutor-cards">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div className="cis-title-2col">
                <h2 className="cis-title-1 mb-0">
                  {translation?.PrivateClass}
                </h2>
                <FormControlLabel
                  control={
                    <Switch
                      size="large"
                      checked={activeClasses}
                      onChange={showActiveClasses}
                      name="active"
                    />
                  }
                  label={activeClasses == undefined ? '' : activeClasses ? `${translation?.ActiveCalls}` : `${translation?.CompletedCalls}`}
                  className="cis-label-btn"
                />
              </div>
            </Grid>
          </Grid>
          {classes === undefined ? (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <CircularProgress />
            </div>
          ) : classes.length === 0 ? (<>
          <p>{translation?.NoClassesFound}</p>
          <div className="coming-soon">
							<div className="cs-inner-box">- <span>{translation?.coming}</span> <span>{translation?.soon}</span> -</div>
						</div>
          </>
          ) : isActive ? (
            filteredClasses?.map((classDetail) => (
              <ClassDetailCard
                key={classDetail.TopicName}
                classDetail={classDetail}
                showClassDetails={showClassDetails}
                showChatTab={showChatTab}
                translations={translation}
              />
            ))
          ) : (<>
          <p>{translation?.NoClassesFound}</p>
          <div className="coming-soon">
							<div className="cs-inner-box">- <span>{translation?.coming}</span> <span>{translation?.soon}</span> -</div>
						</div>
          </>
          )}
        </div>
      </YasaContainer>
      <img src="../../images/bottom-bg.png" alt="" className="cloud-img" />
    </>
  );
};

export default PrivateClass;

export const ErrorModal = () => {
  const dispatch = useDispatch();
  const errorMessage = useSelector(
    ({ modalReducer }) => modalReducer.errorMessage
  );
  return (
    <div id="myModal" className="error-modal">
      <div className="error-modal-content">
        <span className="error-modal-close" onClick={() => dispatch(closeModal())}>
          &times;
        </span>
        <div className="cis-modal-wrap">
          <ErrorOutlineIcon color="error" />
          {/* <p>{errorMessage}</p> */}
          { errorMessage?.includes('https://onelink.to/39wfhz') ? 
            <div>
              <img className="d-block"  src="../../images/onelink.svg" alt="Not Found" style={{margin: '10px auto'}} />
              <a href="https://onelink.to/39wfhz" target="_blank">{errorMessage?.replace('https://onelink.to/39wfhz', '')}</a> 
            </div> : <p>{errorMessage}</p>
          }
        </div>
      </div>
    </div>
  );
};
