import * as actionTypes from "./actionTypes";
import * as apiCall from "../services/api";
import { API_BASE_URL } from "../config/apiConfig";

const isObjectEmpty = (objectName) => {
  return Object.keys(objectName).length === 0
}

const getTutorProfileSuccess = (data) => {
  const noProfile = isObjectEmpty(data.Data)
  return {
    type: actionTypes.GET_TUTOR_PROFILE,
    payload: {
      profile: data.Data,
    },
  };
};

export const getTutorProfile = () => async (dispatch) => {
  return await apiCall
    .axiosGet(`${API_BASE_URL}/GetTutorProfileData`)
    .then((response) => {
      if (response) {
        dispatch(getTutorProfileSuccess(response?.data));
        sessionStorage.setItem("ProfileInfo", JSON.stringify(response.data.Data));
      }
    })
    .catch((error) => {
      console.log('Error: ', error);
    });
};