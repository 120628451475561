import * as actionTypes from "../actions/actionTypes";

const initialState = {};
const rejectQuickCallReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.REJECT_QUICK_CALLS:
      return {
        rejectQuickCalls: action.payload.rejectQuickCalls,
      };

    default:
      return state;
  }
};
export default rejectQuickCallReducer;
