module.exports = {
  SET_INITIAL_STATE: "SET_INITIAL_STATE",
  GET_CLASSES: "GET_CLASSES",
  OPEN_MODAL: "OPEN_MODAL",
  CLOSE_MODAL: "CLOSE_MODAL",
  USER_PHONE_NUMBER: "USER_PHONE_NUMBER",
  GET_COUNTRY_CODE: "GET_COUNTRY_CODE",
  GET_TUTOR_PROFILE: "GET_TUTOR_PROFILE",
  TUTOR_ONLINE_STATUS: "TUTOR_ONLINE_STATUS",
  SAVE_SESSION_START_TIME: "SAVE_SESSION_START_TIME",
  GET_SESSION_COST: "GET_SESSION_COST",
  GET_QUESTION_TYPE: "GET_QUESTION_TYPE",
  SAVE_SESSION_RATINGS:"SAVE_SESSION_RATINGS",
  GET_TUTOR_DASHBOARD: "GET_TUTOR_DASHBOARD",
  RECEIVE_QUICK_CALL: "RECEIVE_QUICK_CALL",
  GET_CANCELLATION_REASON: "GET_CANCELLATION_REASON",
  REJECT_QUICK_CALLS: "REJECT_QUICK_CALLS",
  GET_TUTOR_DETAILED_FEEDBACK: "GET_TUTOR_DETAILED_FEEDBACK",
  GET_SESSION_TODAY: "GET_SESSION_TODAY",
  GET_TUTOR_TRANSACTION: "GET_TUTOR_TRANSACTION",
  GET_TUTOR_WITHDRAW: "GET_TUTOR_WITHDRAW",
  WITHDRAW_PAYMENTS: "WITHDRAW_PAYMENTS",
  GET_TRANSLATIONS: "GET_TRANSLATIONS",
  GET_HELPER_DATA: "GET_HELPER_DATA",
  GET_BOARDS: "GET_BOARDS",
  UPDATE_TUTOR_PROFILE: "UPDATE_TUTOR_PROFILE"
};
