import React, { useState, useEffect } from "react";
import { API_BASE_URL } from "../../../config/apiConfig";
import { axiosPost } from "../../../services/api";
import { useParams } from "react-router-dom";
import Events from "./Events";
import { socket } from "../../../socket";
import { decryptData } from "../../../encryptDecrypt";

const ChatRoom = () => {
  const [isConnected, setIsConnected] = useState(socket.connected);
  const [chatEvents, setChatEvents] = useState([]);
  const userName = JSON.parse(sessionStorage.getItem("userInfo"))?.uid;

  const { encryptClassId } = useParams();
  const classId = decryptData(encryptClassId);

  const fetchChatBackup = () => {
    const data = {
      ClassID: `C${classId}`,
    };
    axiosPost(`${API_BASE_URL}/GetChatdata`, data).then((res) => {
      if (res.data.Message === "User is not validated") {
        sessionStorage.removeItem("loginToken");
        sessionStorage.removeItem("userInfo");
        window.location.href = "/login";
      } else setChatEvents(res.data.Data);
    });
  };

  useEffect(() => {
    fetchChatBackup();
    function onConnect() {
      socket.emit("join", `C${classId}`);
      setIsConnected(true);
    }

    function onDisconnect() {
      setIsConnected(false);
    }

    function onChatEvent(value, person, userID, profileIMG, valueDataType) {
      const currentTime = new Date().getTime();
      const dateObj = new Date(currentTime);
      const dateString = dateObj.toLocaleString("en-US", { timeZone: "UTC" });
      setChatEvents((previous) => [
        ...previous,
        {
          person: person,
          message: value,
          time: dateString,
          UserUID: userID,
          UserPic: profileIMG,
          DataType: valueDataType,
        },
      ]);
    }

    socket.on("connect", onConnect(userName));
    socket.on("disconnect", () => onDisconnect(userName));
    socket.on("chat1", onChatEvent);

    return () => {
      socket.off("connect", onConnect(userName));
      socket.off("disconnect", () => onDisconnect(userName));
      socket.off("chat1", onChatEvent);
    };
  }, []);

  return (
    <div className="ChatRoom">
      {/* <ConnectionState isConnected={isConnected} /> */}
      <Events events={chatEvents} classId={classId} />
      {/* <ConnectionManager /> */}
      {/* <MyForm /> */}
    </div>
  );
};

export default ChatRoom;
