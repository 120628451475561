import * as actionTypes from "../actions/actionTypes";

const initialState = {};

const getSessionTodayReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_SESSION_TODAY:
      return {
        sessionToday: action.payload.sessionToday,
      };

    default:
      return state;
  }
};
export default getSessionTodayReducer;

