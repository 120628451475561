import React, { useEffect } from "react";
import "./index.css";
import { Routes, Route, useNavigate } from "react-router-dom";
import { auth } from "./firebase.js";
import useSocket from "./custom-hooks/useSocket.js";
import { onIdTokenChanged, signOut } from "firebase/auth";

import LoginPage from "./components/login/LoginPage";
import SelectStudy from "./components/login/SelectStudy";
import ProtectedRoute from "./components/login/ProtectedRoute";
import PrivateClass from "./components/private-classes/PrivateClass";
import ClassDetailMain from "./components/private-classes/ClassDetailMain";
import { UserAuthContextProvider } from "./context/UserAuthContext";
import LaunchClassRoom from "./components/agora/LaunchClassRoom";
import QuickCalls from "./components/quick-calls/QuickCalls";
import LaunchQuickCallRoom from "./components/agora/LaunchQuickCallsRoom";
import Performance from "./components/performance/Performance";
import SessionHistory from "./components/session-history/SessionHistory";
import Payment from "./components/payment/Payment";
import WithdrawPaymentPage from "./components/withdraw-payment/WithdrawPayment";
import LaunchHireTutorClassRoom from "./components/agora/LaunchHireTutorClassRoom";
import PaymentHistory from "./components/payment-history/PaymentHistory";
import SingleSignon from "./components/singlesignon/SingleSignon";
import NoPage from "./components/no-page/NoPage";
import Quick from "./components/quick/quick";
import { axiosGet } from "./services/api.js";
import { API_BASE_URL } from "./config/apiConfig.js";

const App = () => {
  const navigate = useNavigate();
  const { socket, isConnected } = useSocket();

  // const refreshToken = async () => {
  //   const user = auth.currentUser;
  //   const userInfo = JSON.parse(sessionStorage.getItem('loginToken'));
  //   if (user && userInfo?.expireTime >= (Date.now() + 25 * 60 * 1000)) {
  //     try {
  //       const refreshedToken = await user.getIdToken(true);
  //       console.log('refreshedToken: ', refreshedToken);
  //       if (userInfo) {
  //         const payload = {
  //           idToken: refreshedToken,
  //           expiresIn: userInfo?.expiresIn,
  //           localId: userInfo?.localId,
  //           isNewUser: userInfo?.isNewUser,
  //           phoneNumber: userInfo?.phoneNumber,
  //           expireTime: Date.now()
  //         };
  //         sessionStorage.setItem('loginToken', JSON.stringify(payload));
  //       } else {
  //         // console.log('User info not found in session storage');
  //       }
  //     } catch (error) {
  //       console.error('Error refreshing token:', error);
  //     }
  //   } else {
  //     // console.log('User not signed in');
  //   }
  // };

  const refreshToken = async () => {
    const userInfo = JSON.parse(sessionStorage.getItem('loginToken') || '{}');
    if (userInfo && Date.now() >= (userInfo?.expireTime + 25 * 60 * 1000)) {
      try {
        const response = await axiosGet(`${API_BASE_URL}/CustomToken`);
        console.log('refresh token: ', new Date().toLocaleTimeString(), response);
        const payload = {
          idToken: response?.data?.Token,
          expiresIn: userInfo?.expiresIn,
          localId: userInfo?.localId,
          isNewUser: userInfo?.isNewUser,
          phoneNumber: userInfo?.phoneNumber,
          expireTime: Date.now()
        };
        sessionStorage.setItem('loginToken', JSON.stringify(payload));
      } catch (error) {
        console.error('Error refreshing token:', error);
      }
    }
  };

  useEffect(() => {
    const refreshInterval = 25 * 60 * 1000; // Refresh token every 25 minutes
    const refreshTimer = setInterval(refreshToken, refreshInterval);

    return () => {
      clearInterval(refreshTimer);
    };
  }, []);

  useEffect(() => {
    const handleLoginSessionResponse = (data) => {
      const loginSessionResponse = JSON.parse(data);
      const deviceId = localStorage.getItem("deviceId");
      if (loginSessionResponse?.Data?.DeviceID !== deviceId) {
        signOut(auth);
        sessionStorage.clear();
        navigate('/single-signon');
      }
    };

    socket?.on('LoginSessionResponseEvent', handleLoginSessionResponse);
    return () => {
      socket?.off('LoginSessionResponseEvent', handleLoginSessionResponse);
    };
    
  }, [isConnected]);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') refreshToken();
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  return (
    <Routes>
      <Route path="/" element={<ProtectedRoute><SelectStudy /></ProtectedRoute>} />
      <Route path="/login" element={<UserAuthContextProvider><LoginPage /></UserAuthContextProvider>} />
      <Route path="/tutor-private-classes" element={<ProtectedRoute><PrivateClass /></ProtectedRoute>} />
      <Route path="/private-classes" element={<ProtectedRoute><PrivateClass /></ProtectedRoute>} />
      <Route path="/class-details/:encryptClassId" element={<ProtectedRoute><ClassDetailMain /></ProtectedRoute>} />
      <Route path="/classroom/:encryptClassId/:encryptSessionId" element={<ProtectedRoute><LaunchClassRoom /></ProtectedRoute>} />
      <Route path="/class-details/:encryptClassId/chat" element={<ProtectedRoute><ClassDetailMain index={4} /></ProtectedRoute>} />
      <Route path="/quick" element={<ProtectedRoute><QuickCalls index={4} /></ProtectedRoute>} />
      <Route path="/performance/:callType" element={<ProtectedRoute><Performance index={4} /></ProtectedRoute>} />
      <Route path="/session-history" element={<ProtectedRoute><SessionHistory index={4} /></ProtectedRoute>} />
      <Route path="/payment" element={<ProtectedRoute><Payment index={4} /></ProtectedRoute>} />
      <Route path="/withdraw-payment" element={<ProtectedRoute><WithdrawPaymentPage index={4} /></ProtectedRoute>} />
      <Route path="/quick-calls-room/:sessionID" element={<ProtectedRoute><LaunchQuickCallRoom /></ProtectedRoute>} />
      <Route path="/hire-class-room/:sessionID" element={<ProtectedRoute><LaunchHireTutorClassRoom /></ProtectedRoute>} />
      <Route path="/payment-history" element={<ProtectedRoute><PaymentHistory /></ProtectedRoute>} />
      <Route path="/receive-calls" element={<ProtectedRoute><Quick index={4} /></ProtectedRoute>} />
      <Route path="/single-signon" element={<SingleSignon />} />
      <Route path="*" element={<NoPage />} />
    </Routes>
  );
}

export default App;
