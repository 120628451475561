import * as actionTypes from "../actions/actionTypes";

const initialState = {};
const getBoardsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_BOARDS:
      return {
        boards: action.payload.boards,
      };

    default:
      return state;
  }
};
export default getBoardsReducer;
