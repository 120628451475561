import * as actionTypes from "./actionTypes";
import * as apiCall from "../services/api";
import { API_BASE_URL } from "../config/apiConfig";

const getTutorTransactionSuccess = (data) => {
  return {
    type: actionTypes.GET_TUTOR_TRANSACTION,
    payload: {
      tutorTransaction: data.Data,
    },
  };
};

export const getTutorTransaction = (payload) => async (dispatch) => {
  return await apiCall
    .axiosPost(`${API_BASE_URL}/GetTutorTransaction`, payload)
    .then((response) => {
      if (response) {
        dispatch(getTutorTransactionSuccess(response.data));
      }
    })
    .catch((error) => {
      console.log('Error: ', error);
    });
};