import * as actionTypes from "../actions/actionTypes";

const initialState = {};

const getCancellationReasonReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_CANCELLATION_REASON:
      return {
        cancellationReason: action.payload.cancellationReason,
      };

    default:
      return state;
  }
};
export default getCancellationReasonReducer;

