import React from "react";
import {
	Button,
	Card,
	CardContent,
	Chip,
	Grid,
	Rating,
	Typography,
} from "@mui/material";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { Box, Stack } from "@mui/system";
import ReactTimeAgo from "react-time-ago";
import Moment from "react-moment";
import { encryptData } from "../../encryptDecrypt";

const ClassDetailCard = ({
	classDetail,
	showClassDetails,
	showChatTab,
	translations,
}) => {
	const appLanguage = localStorage.getItem("I18N_LANGUAGE");

	return (
		<div className="cis-private-class">
			<Grid container spacing={2}>
				<Grid item xs={12} md={8}>
					<Card sx={{ display: "flex" }} className="cis-card">
						<Box sx={{ display: "flex", flexDirection: "column" }}>
							<CardContent sx={{ flex: "1 1 auto" }}>
								<Typography component="div" variant="h5">
									{classDetail?.Value} &nbsp;&nbsp;
									{
										classDetail?.ClassStatus === 'Active' ? <span className="status-info status-active"> {translations?.ActiveCalls}  </span> :
											classDetail?.ClassStatus === 'Complete' ? <span className="status-info status-completed"> {translations?.CompletedCalls}  </span> :
												classDetail?.ClassStatus === 'Cancelled' && <span className="status-info status-cancelled"> {translations?.CancelledCalls}  </span>
									}
								</Typography>

								<div className="cis-Chips-2 mb-0">
									<Chip
										icon={<WatchLaterIcon />}
										variant="outlined"
										label={classDetail.ClassesTimmings}
									/>
									<Chip
										icon={<CalendarTodayIcon />}
										variant="outlined"
										label={`Starts on ${classDetail?.ClassesDate}`}
									/>
								</div>
								<p className="mt-0">{classDetail?.ShortDescription}</p>
								<div>
									<Stack direction="row" spacing={2}>
										{/* <Button
											className={`cis-class-status-btn ${
												classDetail?.ClassStatus === "Active"
												? "cis-active-btn"
												: "cis-complete-btn"
											}`}
											size="large"
											sx={{ px: 4 }}
											>
											{translations?.[classDetail?.ClassStatus]}
											</Button> */}
										<Button
											className="cis-detail-btn"
											size="large"
											sx={{ px: 4 }}
											onClick={() => showClassDetails(encryptData(classDetail.ID))}
										>
											{translations?.Detail}
										</Button>
									</Stack>
								</div>
							</CardContent>
						</Box>
					</Card>
				</Grid>
				<Grid item xs={12} md={4}>
					<Box className="cis-teacher-card">
						<div className="cis-teacher-info">
							<img
								src={classDetail?.studentData?.ProfilePictureUrl}
								className="cis-teacher-img"
								alt=""
							/>
							<div className="cis-content">
								<h5>{classDetail?.studentData?.UserName}</h5>
								<Rating name="read-only" value="3" readOnly />
								{/* <Button
									variant="outlined"
									sx={{ px: 2 }}
									onClick={() => showChatTab(classDetail.ID)}
									>
									Message
									</Button> */}
							</div>
						</div>
					</Box>
				</Grid>
			</Grid>
		</div>
	);
};

export default ClassDetailCard;
